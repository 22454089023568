import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import { CardActionArea } from "@mui/material";
import s from "./GridCompetitions.module.sass";
import { NavLink } from "react-router-dom";
import { ROOT_URL } from "../../constants";
import { nanoid } from "nanoid";
import { Language, getCurrentLanguage } from "../../language/lang";

import Countdown from "react-countdown";
import RealTime from "./RealTime";

import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";

const GridCompetitions = (props) => {
  function getAmounts(users) {
    let clubs = [];
    let params = {
      clubs: 0,
      participants: 0,
      users: 0,
      countries: 0,
    };
    users.forEach((el) => {
      params.users++;
      params.participants += el.disciplines.length;
      if (el.club?.name && !clubs.includes(el.club.name))
        clubs.push(el.club.name);
    });
    params.countries = [
      ...new Set(users.map((user) => user.country).filter((el) => Boolean(el))),
    ].length;
    params.clubs = clubs.length;
    return params;
  }

  return (
    <div className={s.gridBlock}>
      {props.events.map((el) => {
        let t = new Date(Number(el.date_from));

        const formattedDate = new Intl.DateTimeFormat("ru-RU").format(t);
        let registration = "no";
        let dateNow = Date.now();
        let flagRealTime = false;

        if (dateNow > Number(el.date_end_registration)) {
          flagRealTime = true;
        }
        if (
          el.date_start_registration < dateNow &&
          dateNow < el.date_end_registration
        ) {
          registration = "green";
        }
        if (el.discount.length > 2) {
          JSON.parse(el.discount).map((dis, index) => {
            let dateFrom = Number(dis.dateFrom);
            let dateTo = Number(dis.dateTo);
            let dateCompetitions = Date.now();

            // const formatFrom = new Intl.DateTimeFormat("ru-RU").format(
            //   Number(dis.dateFrom)
            // );
            // const formatTo = new Intl.DateTimeFormat("ru-RU").format(
            //   Number(dis.dateTo)
            // );
            // const formatNow = new Intl.DateTimeFormat("ru-RU").format(
            //   dateCompetitions
            // );
            // console.log(el.name + "------------" + index);
            // console.log(formatFrom + "  dateFrom");
            // console.log(formatTo + " dateTo");
            // console.log(formatNow + "  dateNow");

            if (
              index === 1 &&
              dateCompetitions > dateFrom &&
              dateCompetitions < dateTo
            ) {
              registration = "green";
            } else if (
              index === 2 &&
              dateCompetitions > dateFrom &&
              dateCompetitions < dateTo
            ) {
              registration = "yellow";
            } else if (
              index === 3 &&
              dateCompetitions > dateFrom &&
              dateCompetitions < dateTo
            ) {
              registration = "red";
            } else {
              registration = "no";
            }
          });
        }

        // console.log(registration + "  " + el.id);
        return (
          <div key={nanoid()} className={s.gridElem}>
            <NavLink
              to={"/event/" + el.id}
              style={{
                textDecoration: "none",
              }}
            >
              <Card variant="outlined" className={s.card}>
                <CardActionArea className={s.cardAction}>
                  <CardMedia
                    className={s.img}
                    component="img"
                    image={`${ROOT_URL}/img/events/${el.image}`}
                  />
                  <CardContent className={s.cardContentWrapper}>
                    <h2 className={s.titleCard}>
                      {getCurrentLanguage() === Language.EN && el.name_eng
                        ? `${el.name_eng}`
                        : `${el.name}`}
                    </h2>
                    <div className={s.contentCard}>
                      {formattedDate}
                      <span className={s.address}>{el.address}</span>
                      {/* <span>
                      {isNaN(Number(el.date_from))
                        ? el.date_from
                        : new Date(+el.date_from).toLocaleDateString("ru-RU")}
                    </span> */}{" "}
                      {/* <h3 className={s.date}>{GetGridDate(el)}</h3>{" "} */}
                      {/* <h3 className={s.date}>
                        {Text.EventInfo.Registration.TheFightsStartAt}
                        {el.time}
                      </h3> */}
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        <Box className={s.participants}>
                          <i class="fas fa-users" title="Участников"></i>
                          {el.users &&
                            getAmounts(JSON.parse(el.users)).participants}
                        </Box>
                        {registration === "green" && (
                          <div className={s.icons} style={{ color: "green" }}>
                            <i class="fas fa-check-circle"></i>
                            Ранняя регистрация
                          </div>
                        )}

                        {registration === "red" && (
                          <div className={s.icons} style={{ color: "red" }}>
                            {" "}
                            <ReportRoundedIcon
                              sx={{ fill: "#f23636", width: "12px" }}
                            />
                            Средняя регистрация
                          </div>
                        )}
                        {registration === "yellow" && (
                          <div className={s.icons} style={{ color: "orange" }}>
                            Поздняя регистрация{" "}
                            <HourglassBottomRoundedIcon
                              sx={{ width: "12px" }}
                            />
                          </div>
                        )}

                        <Box
                          className={`${s.timer} ${
                            flagRealTime ? s.timer_closed : ""
                          }`}
                        >
                          {/* <span>{Text.EventInfo.Registration.TimeLeft}</span> */}
                          <b>
                            <Countdown
                              date={
                                new Date(RealTime(el)[0].dateEndRegisteration)
                              }
                              renderer={RealTime(el)[1]}
                            />
                          </b>
                        </Box>
                      </Box>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default GridCompetitions;
