import React, { useState } from "react";
import styles from "./TeamCard.module.scss"; // Импортируем стили как модуль
import ParticipantsList from "./ParticipantsList";

const TeamCard = ({
  team,
  toggleParticipants,
  downloadParticipants,
  downloadTeamDiploma,
  index,
}) => {
  const {
    id,
    logo,
    name,
    location,
    rating,
    points,
    wins,
    losses,
    firstPlaces,
    secondPlaces,
    thirdPlaces,
    participants,
  } = team;
  const [ParticipantsShow, SetParticipantsShow] = useState(false);
  // Определяем класс для места
  const placeClass = (index) => {
    if (index === 0) return styles.gold;
    if (index === 1) return styles.silver;
    if (index === 2) return styles.bronze;
    return styles.blue;
  };

  return (
    <div>
      <div className={styles.teamCard}>
        <div className={styles.teamInfo}>
          <div className={`${styles.placeCircle} ${placeClass(index)}`}>
            {index + 1}
          </div>
          <img src={logo} alt={`Лого команды ${name}`} />
          <div className={styles.teamDetails}>
            <div className={styles.teamName}>{name}</div>
            <div className={styles.teamLocation}>{location}</div>
          </div>
        </div>
        <div className={styles.teamStatsButtons}>
          <div className={styles.teamStats}>
            <div>
              <i className="fas fa-star"></i> {rating}
              <span className={styles.tooltip}>Рейтинг</span>
            </div>
            <div>
              <i className="fas fa-coins"></i> {points}
              <span className={styles.tooltip}>Баллы</span>
            </div>
            <div className={styles.win}>
              <i className="fas fa-fist-raised"></i> {wins}
              <span className={styles.tooltip}>Побед</span>
            </div>
            <div className={styles.loss}>
              <i className="fas"></i> {losses}
              <span className={styles.tooltip}>Поражений</span>
            </div>
            <div className={styles.firstPlace}>
              <i className="fas fa-medal" style={{ color: "#fad201" }}></i>{" "}
              {firstPlaces}
              <span className={styles.tooltip}>1 место</span>
            </div>
            <div className={styles.secondPlace}>
              <i className="fas fa-medal" style={{ color: "silver" }}></i>{" "}
              {secondPlaces}
              <span className={styles.tooltip}>2 место</span>
            </div>
            <div className={styles.thirdPlace}>
              <i className="fas fa-medal" style={{ color: "#cd7f32" }}></i>{" "}
              {thirdPlaces}
              <span className={styles.tooltip}>3 место</span>
            </div>
          </div>
          <div className={styles.buttons}>
            <button
              className={`${styles.participantsButton} ${
                ParticipantsShow ? styles.active : ""
              }`}
              onClick={() => SetParticipantsShow(!ParticipantsShow)}
            >
              <i className="fas fa-users"></i> Участники ({participants?.length}
              )
            </button>
            <button
              className={styles.disableBut}
              onClick={() => downloadParticipants(id)}
              disabled={true}
            >
              <i className="fas fa-file-download"></i> Выписка
            </button>
            <button
              className={styles.disableBut}
              onClick={() => downloadTeamDiploma(id)}
            >
              <i className="fas fa-file-pdf"></i> Диплом
            </button>
          </div>
        </div>
      </div>
      {
        <div
          className={styles.ParticipantsList}
          style={{
            maxHeight: ParticipantsShow
              ? participants.length * 250 + "px"
              : "0px",
          }}
        >
          {participants && <ParticipantsList participants={participants} />}
        </div>
      }
    </div>
  );
};

export default TeamCard;
