import { Button, IconButton, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React, { useState } from 'react'
import FightProcessContainer from '../FightProcess/FightProcessContainer'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { nanoid } from 'nanoid';
import s from './Fights.module.sass'
import { getCookie } from "../../AppExtComponents.ts";
import Loading from '../Loading/Loading';
import FightsDiagramContainer from '../FightsDiagram/FightsDiagramContainer';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import FightsFilter from '../filters/FightsFilter';
import EditIcon from '@mui/icons-material/Edit';
import { Text, getCurrentLanguage } from "../../language/lang";
import AdjustIcon from '@mui/icons-material/Adjust';
import ControlledModal from '../ControlledModal/ControlledModal';
import StreamFrame from '../StreamFrame/StreamFrame';



const Fights = ({ fightsData, fightsByDays, platformURLs, setPlatformFights, isFetching, refreshFights, setCurrentFight, selectedDay, setSelectedDay }) => {
    const [isFightProcessOpen, setFightProcessOpen] = useState(false)
    const [isCompletedFightsHide, setCompletedFightsHide] = useState(true)
    const [basketParams, setBasketParams] = useState({
        isOpen: false,
        basketName: null
    })
    const [streamParams, setStreamParams] = useState({
        isOpen: false,
        streamURL: null,
        title: null
    })

    const providePropsToOpenFight = ({ fight }) => {
        return () => {
            setCurrentFight(fight)
            setFightProcessOpen(true)
        }
    }

    const closeFightProcessHandler = () => {
        setFightProcessOpen(false)
    }

    const providePropsToOpenBasket = (basketName) => {
        return () => {
            setBasketParams({
                isOpen: true,
                basketName
            })
        }
    }

    const handleCloseBasket = () => {
        setBasketParams({
            isOpen: false,
            basketName: null
        })
    }

    const hideCompletedFights = e => {
        setCompletedFightsHide(e.target.checked)
    }


    const handleDaySelection = (e, newDay) => {
        if (!newDay) return
        setPlatformFights(fightsByDays[newDay])
        setSelectedDay(newDay)
    }


    const isShowDaysTabs = () => {
        return !fightsByDays.hasOwnProperty('platform_1')
    }


    function getSlicedDateString(date) {
        return date.split('.').slice(0, 2).join('.')
    }


    const checkForPotentialFight = (fight) => {
        // если индекс одного из пользователей равен -1, то это потенциальный поединок
        return fight.users.reduce((_, el) => {
            if (!el.hasOwnProperty('id')) return true
            return +el?.id === -1
        }, false)
    }


    return (<>
        <Loading isLoading={isFetching} />
        <div className={s.wrapper}>
            <div className={`${s.filter} ${s.filter_desktop}`}>
                <FightsFilter isExpanded />
            </div>
            <div className={s.fightsWrapper}>
                <h3 className={s.title}>{Text.Fights.CheduleTitle}</h3>
                <div className={`${s.filter} ${s.filter_mobile}`}>
                    <FightsFilter />
                </div>

                <CustomCheckbox label={Text.Fights.HideFights} onChange={hideCompletedFights} defaultChecked />
                <br />

                {isShowDaysTabs() && (
                    <div className={s.daysToggle}>
                        <ToggleButtonGroup
                            value={selectedDay}
                            exclusive
                            onChange={handleDaySelection}
                        >
                            {Object.keys(fightsByDays).map(date => (
                                <ToggleButton key={nanoid()} value={date}>
                                    {getSlicedDateString(date)}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </div>
                )}

                <div className={s.platformsWrapper}>
                    {Object.values(fightsData).map((platform, j) => {
                        const platformURL = platformURLs.find((el) => el.platform === j + 1)
                      
                        return (
                            <div key={nanoid()} className={s.platform}>
                                <div className={s.platform__header}>
                                    <Stack direction='row' alignItems='center' spacing={2}>
                                        <span>№{j + 1}</span>
                                        <Button
                                            variant='text'
                                            startIcon={<AdjustIcon sx={{ fill: 'red' }} />}
                                            sx={{
                                                visibility: platformURL && platformURL.url !== ''
                                                    ? 'visible'
                                                    : 'hidden'
                                            }}
                                            onClick={() => setStreamParams({
                                                isOpen: true,
                                                streamURL: platformURL.url,
                                                title: platformURL.platform
                                            })}
                                        >
                                            Трансляция
                                        </Button>
                                    </Stack>
                                    <span>{Text.Fights.StartAt} {platform.fights[0] ? platform.fights[0].time : "--:--"}</span>
                                </div>
                                {platform.fights.map((fight, i) => {
                                    if (fight.hasOwnProperty('result') && isCompletedFightsHide)
                                        return null
                                    else
                                        return (
                                            <div key={nanoid()} className={s.platform__fight}>
                                                <div className={s.platform__info}>
                                                    <span style={{ color: 'teal' }}># {fight.fightNumber}</span>
                                                    <span className={s.platform__basket}
                                                        onClick={providePropsToOpenBasket(fight.basket)}
                                                    >
                                                        {fight.basket}
                                                    </span>
                                                    {fight.users.map(user => (
                                                        <span key={nanoid()}>
                                                            {user.fio}{user.club?.name ? `, (${user.club?.name})` : ""}
                                                        </span>
                                                    ))}
                                                </div>
                                                <div className={s.platform__button}>
                                                    {getCookie('admin') && (
                                                        <>
                                                            {!fight.hasOwnProperty('result') && (
                                                                <Button
                                                                    onClick={providePropsToOpenFight({ fight })}
                                                                    disabled={fight.hasOwnProperty('result') || checkForPotentialFight(fight)}
                                                                >
                                                                    Поединок
                                                                </Button>
                                                            )}
                                                            {fight.hasOwnProperty('result') && (
                                                                <IconButton
                                                                    onClick={providePropsToOpenFight({ fight })}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className={s.platform__clock}>
                                                        <AccessTimeIcon sx={{ fill: 'gray', fontSize: 18, mr: '4px' }} />
                                                        <span>{fight.time}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>


        <FightsDiagramContainer
            name={basketParams.basketName}
            isOpen={basketParams.isOpen}
            onClose={handleCloseBasket}
            isReqiuredToDownload={true}
        />

        <FightProcessContainer
            isOpen={isFightProcessOpen}
            onClose={closeFightProcessHandler}
        />
        <ControlledModal
            contentClassName={s.streamModal}
            title={`Платформа №${streamParams.title}`}
            isOpen={streamParams.isOpen}
            onClose={() => setStreamParams({
                isOpen: false,
                streamURL: null,
                title: null
            })}
        >
            <br />
            {streamParams.streamURL
                ? (
                    <StreamFrame src={streamParams.streamURL?.replace('live', 'embed')} />
                )
                : <h3>Не верный URL</h3>
            }

        </ControlledModal>
    </>)
}

export default Fights