import React, { useEffect } from 'react'
import Fights from './Fights'
import { useDispatch, useSelector } from 'react-redux';
import { getEventState } from '../../redux/selectors/getEventState'
import { useParams } from 'react-router-dom';
import { getFightsOnPlatform } from '../../redux/reducers/fight/fightThunks';
import { setCurrentFight, setFilteredFights, setFights, setFightsByDays, setSelectedDay, filterFights } from '../../redux/reducers/fight/fight';
import { getEvent } from "../../redux/reducers/event/eventThunks.ts";
import _ from 'lodash';
import Loading from '../Loading/Loading';



const FightsContainer = () => {
    const { eventID } = useParams()
    const { fights, fightsByDays, fetching, selectedDay } = useSelector(state => state.fight)
    const isFetchingEvent = useSelector(state => state.event.isFetching)
    const platformURLs = useSelector(state => state.event.event?.translation_urls)
    const dispatch = useDispatch()


    useEffect(() => {
        loadFights()
        dispatch(getEvent({ id: eventID }))
        return () => {
            dispatch(setFilteredFights({}))
            dispatch(setFights({}))
            dispatch(setFightsByDays({}))
            dispatch(setSelectedDay(null))
        }
    }, [])

    useEffect(() => {
        if (fightsByDays.hasOwnProperty('platform_1')) return
        if (!_.isEmpty(fightsByDays)) {
            if (!selectedDay) {
                const day = Object.keys(fightsByDays)[0]
                dispatch(setSelectedDay(day))
                dispatch(setFights(fightsByDays[day]))
                dispatch(filterFights())
            } else {
                dispatch(setFights(fightsByDays[selectedDay]))
                dispatch(filterFights())
            }
        }
    }, [fightsByDays])


    function loadFights() {
        dispatch(getFightsOnPlatform({ id: eventID }))
    }

    const setFight = (fight) => {
        dispatch(setCurrentFight(fight))
    }

    const setPlatformFights = (platfromFights) => {
        dispatch(setFights(platfromFights))
    }

    const handleSetSelectedDay = (day) => {
        dispatch(setSelectedDay(day))
    }

    if (_.isEmpty(fightsByDays)) return <Loading isLoading={true} />
    if (fightsByDays.hasOwnProperty('platform_1')) return <h2>Данный формат поединков больше не поддерживается</h2>
    return (
        <Fights
            fightsData={fights}
            fightsByDays={fightsByDays}
            isFetching={fetching.isLoading || isFetchingEvent.gettingEvent}
            platformURLs={platformURLs ? JSON.parse(platformURLs) : []}
            refreshFights={loadFights}
            setCurrentFight={setFight}
            setPlatformFights={setPlatformFights}
            selectedDay={selectedDay}
            setSelectedDay={handleSetSelectedDay}
        />
    )
}

export default FightsContainer