import React, { useState, useEffect, useMemo, useCallback, memo } from "react";
import styles from "./Filters.module.css";

import {
  setFilteredParticipants,
  resetFilteredParticipants,
} from "../../redux/reducers/event/event.ts";
import { getEventState } from "../../redux/selectors/getEventState";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types"; // Добавляем PropTypes для проверки типов
import { registrationStatus } from "../../constants.js";
import { forIn } from "lodash";
const Filters = memo(function Filters({
  toggleFilterVisibility,
  isFilterVisible,
  availableWeights,
  resetFilterFlag,
}) {
  const {
    allCategory,
    allSex,
    allStatus,
    participants,
    disciplinesNames,
    allOld,
  } = useSelector(getEventState);
  const dispatch = useDispatch();

  // Состояние фильтров
  const [filters, setFilters] = useState({
    fio: "",
    club: "",
    city: "",
    region: "",
    country: "",
    disciplines: [],
    ages: [],
    weights: [],
    category: [],
    status: [],
    sex: [],
    categoryOld: [],
  });

  // Функция для получения текста статуса
  const textStatus = (statusNumber) => registrationStatus[statusNumber] || "";

  // Используем данные из Redux без useMemo, так как они меняются редко
  const rankLevels = allCategory;
  const statusesList = allStatus;
  const gendersList = allSex;
  const agesList = allOld;

  // Функция для обновления фильтров, обернутая в useCallback для оптимизации
  const updateFilter = useCallback((field, value) => {
    setFilters((prevFilters) => {
      let newValue = value;
      if (Array.isArray(prevFilters[field])) {
        newValue = prevFilters[field].includes(value)
          ? prevFilters[field].filter((v) => v !== value)
          : [...prevFilters[field], value];
      }

      return {
        ...prevFilters,
        [field]: newValue,
      };
    });
  }, []);
  useEffect(() => {
    setFilters((prev) => {
      let newPrev = {};
      for (let key in prev) {
        if (typeof prev[key] === "string") {
          newPrev[key] = "";
        } else if (Array.isArray(prev[key])) {
          newPrev[key] = [];
        }
      }
      return newPrev;
    });
  }, [resetFilterFlag]);
  // Функция для обновления текстовых полей фильтра, обернутая в useCallback
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  }, []);

  // Мемоизируем отфильтрованных участников для оптимизации
  const filteredParticipants = useMemo(() => {
    return participants.filter((participant) => {
      let flag = true;

      for (let key in filters) {
        if (filters[key] && filters[key].length > 0) {
          const participantValue = participant[key];

          if (Array.isArray(filters[key])) {
            if (participantValue !== undefined) {
              if (Array.isArray(participantValue)) {
                // Если и фильтр, и значение участника являются массивами
                flag =
                  flag &&
                  filters[key].every((filterValue) =>
                    participantValue.includes(filterValue)
                  );
              } else {
                // Если фильтр — массив, а значение участника — строка или число
                flag = flag && filters[key].includes(participantValue);
              }
            } else {
              flag = false;
            }
          } else if (typeof filters[key] === "string") {
            // Фильтрация по текстовым полям
            if (participantValue) {
              const participantFieldValue =
                key === "club"
                  ? participantValue.name?.toUpperCase()
                  : participantValue.toUpperCase();

              flag =
                flag &&
                participantFieldValue.includes(filters[key].toUpperCase());
            } else {
              flag = false;
            }
          }
        }
      }

      return flag;
    });
  }, [participants, filters]);

  // Обновляем список отфильтрованных участников при изменении фильтров
  useEffect(() => {
    dispatch(setFilteredParticipants(filteredParticipants));
  }, [filteredParticipants]);

  return (
    <div className={styles.filterContainer}>
      {isFilterVisible && (
        <div className={styles.filters}>
          {/* Поля для поиска */}
          <div className={styles.filterGroup}>
            <input
              type="text"
              name="fio"
              placeholder="Поиск по ФИО"
              value={filters.fio}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="club"
              placeholder="Поиск по клубу"
              value={filters.club}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="city"
              placeholder="Поиск по городу"
              value={filters.city}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="region"
              placeholder="Поиск по региону"
              value={filters.region}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="country"
              placeholder="Поиск по стране"
              value={filters.country}
              onChange={handleInputChange}
            />
          </div>

          {/* Фильтры по дисциплинам */}
          <div className={styles.filterGroup}>
            {disciplinesNames.map((discipline) => (
              <button
                key={discipline}
                className={
                  filters.disciplines.includes(discipline) ? styles.active : ""
                }
                onClick={() => updateFilter("disciplines", discipline)}
              >
                {discipline}
              </button>
            ))}
          </div>

          {/* Фильтры по возрасту */}
          <div className={styles.filterGroup}>
            {agesList?.map((age) => (
              <button
                key={age}
                className={
                  filters.categoryOld.includes(age) ? styles.active : ""
                }
                onClick={() => updateFilter("categoryOld", age)}
              >
                {age} лет
              </button>
            ))}
          </div>

          {/* Фильтры по весу */}
          <div className={styles.filterGroup}>
            {availableWeights.map((weight) => (
              <button
                key={weight}
                className={
                  filters.weights.includes(weight) ? styles.active : ""
                }
                onClick={() => updateFilter("weights", weight)}
              >
                {weight} кг
              </button>
            ))}
          </div>

          {/* Фильтры по разряду, полу и статусу */}
          <div className={styles.filterGroup}>
            {rankLevels.map((rankLevel) => (
              <button
                key={rankLevel}
                className={
                  filters.category.includes(rankLevel) ? styles.active : ""
                }
                onClick={() => updateFilter("category", rankLevel)}
              >
                {rankLevel}
              </button>
            ))}
            <div className={styles.separator}></div>
            {gendersList.map((gender) => (
              <button
                key={gender}
                className={filters.sex.includes(gender) ? styles.active : ""}
                onClick={() => updateFilter("sex", gender)}
              >
                {gender}
              </button>
            ))}
            <div className={styles.separator}></div>
            {statusesList.map((status) => (
              <button
                key={status}
                className={filters.status.includes(status) ? styles.active : ""}
                onClick={() => updateFilter("status", status)}
              >
                {textStatus(status)}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});

// Добавляем defaultProps и propTypes для проверки типов и значений по умолчанию
Filters.defaultProps = {
  toggleFilterVisibility: () => {},
  isFilterVisible: false,
  availableWeights: [],
};

Filters.propTypes = {
  toggleFilterVisibility: PropTypes.func,
  isFilterVisible: PropTypes.bool,
  availableWeights: PropTypes.arrayOf(PropTypes.number),
};

export default Filters;
