import { parseJSON } from "../../helpers/parseJSON";

export const getEventState = (state) => {
  const disciplinesData = parseJSON({ json: state.event.event.disciplines });
  const disciplinesNames = disciplinesData.map((el) => el.name);

  let allOld = [];
  let allCategory = [];
  let allSex = [];
  let allStatus = [];

  const countOfUsersAndClubs = state.event.countOfUsersAndClubs;
  if (state.event.event.users != undefined) {
    const users = state.event.participants;

    users.forEach((user) => {
      if (!allCategory.includes(user.category)) {
        allCategory.push(user.category);
      }
      if (!allSex.includes(user.sex)) {
        allSex.push(user.sex);
      }
      if (!allStatus.includes(user.status)) {
        allStatus.push(user.status);
      }

      if (!allOld.includes(user.categoryOld)) {
        allOld.push(user.categoryOld);
      }
    });
  }

  return {
    ...state.event,
    disciplinesNames: disciplinesNames,
    disciplinesData: disciplinesData,
    allCategory: allCategory,
    allSex: allSex,
    allStatus: allStatus,
    allOld: allOld,
    countOfUsersAndClubs,
  };
};
