import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import styles from "./Carousel.module.css";

const Carousel = ({ items, title = "Команды-участники" }) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  // Используем useEffect для установки стрелок после рендеринга компонента
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, []);

  return (
    <div className={styles.carouselContainer}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.carouselWrapper}>
        {/* Левая стрелка */}
        <div ref={prevRef} className={`${styles.arrow} ${styles.leftArrow}`}>
          &#10094;
        </div>

        <Swiper
          spaceBetween={0}
          slidesPerView={items.length < 5 ? items.length : 5}
          loop={true}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation]}
        >
          {items.map((item, index) => (
            <SwiperSlide key={item.name}>
              <div className={styles.item}>
                <div className={styles.logoPlaceholder}>
                  <img src={item.logo} alt={item.name} />
                </div>
                <h4 className={styles.itemName}>{item.name}</h4>
                {item.participants && (
                  <p className={styles.participants}>
                    Участников: {item.participants.length}
                  </p>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Правая стрелка */}
        <div ref={nextRef} className={`${styles.arrow} ${styles.rightArrow}`}>
          &#10095;
        </div>
      </div>
    </div>
  );
};

export default Carousel;
