import { useParams } from "react-router-dom";
import s from "./HeaderLeft.module.scss";
import { NavLink } from "react-router-dom";
import ModalCall from "../ModalCall/ModalCall";
import logo from "../../logo.png";
import { getCookie } from "../../AppExtComponents.ts";

import {
  getCurrentLanguage,
  Language,
  setCurrentLanguage,
  Text,
} from "../../language/lang";
import { useEffect, useState } from "react";

const HeaderLeft = ({ hideMenu, setHideMenu, redirectToAccount }) => {
  const url = useParams();
  const userAuth = Boolean(getCookie("user"));
  const [data, setData] = useState();
  const user = () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (userData.id == getCookie("user")) {
      return userData;
    } else {
      return data;
    }
  };

  useEffect(() => {
    if (!Boolean(getCookie("user"))) {
      setData(false);
    } else {
      setData(user());
    }
  }, [Boolean(getCookie("user")), url]);
  const changeLang = () => {
    if (getCurrentLanguage() === "ru") {
      setCurrentLanguage("en");
    } else {
      setCurrentLanguage("ru");
    }
  };

  return (
    <div
      className={`${s.sidebar} ${hideMenu === false && s.collapsed} ${
        s.expanded
      }`}
    >
      <div>
        <div className={s.logo}>
          {/* <img
            src={logo}
            alt=""
            style={{
              height: 40,
              margin: "10px 0",
            }}
          /> */}
          <span className={s["logo-text"]}>COMBAT ARENA</span>
        </div>

        <div className={s.profile} onClick={() => redirectToAccount()}>
          <div className={s["profile-img"]}>
            {data ? (
              <img
                src={`https://pvkgym.ru/fightevent/img/users/${data.image}`}
                alt=""
              />
            ) : (
              <i className="fas fa-user"></i>
            )}
          </div>

          {hideMenu && (
            <div>
              {userAuth === false ? (
                <div className={s.button}>{Text.Header.SignIn}</div>
              ) : (
                <div className={s["profile-info"]}>
                  {data ? (
                    <span>
                      {data?.secondname + " "}
                      {data?.firstname + " "}
                      {data?.thirdname}
                    </span>
                  ) : (
                    ""
                  )}

                  <div className={s["profile-text"]}>
                    {data?.club ? JSON.parse(data.club).name : ""}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={s.divider} onClick={() => setHideMenu(!hideMenu)}>
          <button
            className={s[(hideMenu === false && "collapsed", "toggle-button")]}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
        </div>

        <ul className={s.menu}>
          {/* <li>
          <NavLink
            to="#"
            className={`${s["menu-item"]} ${s.active}`}
            title="Главная"
          >
            <span className={s.icon}>
              <i className="fas fa-home"></i>
            </span>{" "}
            <span className={s.text}>Главная</span>
          </NavLink>
        </li> */}
          <li>
            <NavLink
              to="news"
              className={s["menu-item"]}
              title={Text.Header.News}
            >
              <span className={s.icon}>
                <i className="fas fa-newspaper"></i>
              </span>
              <span className={s.text}>{Text.Header.News}</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/"
              className={s["menu-item"]}
              title={Text.Header.Events}
            >
              <span className={s.icon}>
                <i className="fa fa-calendar-days"></i>
              </span>
              <span className={s.text}>{Text.Header.Events}</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="#"
              className={`${s["menu-item"]} ${s.rating}`}
              title={Text.Header.Rating}
            >
              <span className={s.icon}>
                <i className="fas fa-star"></i>
              </span>{" "}
              <span className={s.text}>{Text.Header.Rating}</span>
              <i className={`fas fa-chevron-down ${s.arrow}`}></i>
            </NavLink>
            <ul className={s["sub-menu"]}>
              <li>
                <NavLink
                  to="/clubsrating"
                  className={s["menu-item"]}
                  title={Text.Header.ClubRating}
                >
                  <span className={s.icon}>
                    <i className="fa-solid fa-user-group"></i>
                  </span>
                  <span className={s.text}>{Text.Header.ClubRating}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sportsmenrating"
                  className={s["menu-item"]}
                  title={Text.Header.AthletesRating}
                >
                  <span className={s.icon}>
                    <i class="fa-solid fa-user"></i>
                  </span>
                  <span className={s.text}>{Text.Header.AthletesRating}</span>
                </NavLink>
              </li>
            </ul>
          </li> */}
          <li>
            <NavLink
              to="/insurance"
              className={s["menu-item"]}
              title={Text.Header.Insurance}
            >
              <span className={s.icon}>
                <i className="fas fa-shield-alt"></i>
              </span>
              <span className={s.text}>{Text.Header.Insurance}</span>
            </NavLink>
          </li>
          <li>
            <ModalCall>
              <NavLink
                to="#"
                className={s["menu-item"]}
                title={Text.Header.Support}
              >
                <span className={s.icon}>
                  <i className="fas fa-question-circle"></i>
                </span>
                <span className={s.text}>{Text.Header.Support}</span>
              </NavLink>
            </ModalCall>
          </li>
        </ul>
      </div>

      {url.eventID != undefined && (
        <div>
          {" "}
          <div className={s.divider}></div>
          <ul className={s.menu}>
            <li>
              <NavLink
                to={"/event/" + url.eventID}
                className={s["menu-item"]}
                title={Text.Header.Information}
              >
                <span className={s.icon}>
                  <i className="fas fa-info-circle"></i>
                </span>{" "}
                <span className={s.text}>{Text.Header.Information}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/event/" + url.eventID + "/categories"}
                className={s["menu-item"]}
                title={Text.Header.Categories}
              >
                <span className={s.icon}>
                  <i class="fa-solid fa-list"></i>
                </span>
                <span className={s.text}>{Text.Header.Categories}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/event/" + url.eventID + "/athletes"}
                className={s["menu-item"]}
                title={Text.Header.Athletes}
              >
                <span className={s.icon}>
                  <i className="fas fa-users"></i>
                </span>{" "}
                <span className={s.text}>{Text.Header.Athletes}</span>
              </NavLink>
            </li>

            {getCookie("admin") && (
              <li>
                <NavLink
                  to={"/event/" + url.eventID + "/settings"}
                  className={s["menu-item"]}
                  title={Text.Header.Settings}
                >
                  <span className={s.icon}>
                    <i className="fas fa-cogs"></i>
                  </span>{" "}
                  <span className={s.text}>{Text.Header.Settings}</span>
                </NavLink>
              </li>
            )}

            {/* <li>
              <NavLink
                to={"/event/" + url.eventID + "/fights"}
                className={s["menu-item"]}
                title={Text.Header.Fights}
              >
                <span className={s.icon}>
                  <i className="fas fa-fist-raised"></i>
                </span>{" "}
                <span className={s.text}>{Text.Header.Fights}</span>
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                to={"/event/" + url.eventID + "/baskets"}
                className={s["menu-item"]}
                title={Text.Header.Grids}
              >
                <span className={s.icon}>
                  <i className="fas fa-network-wired"></i>
                </span>{" "}
                <span className={s.text}>{Text.Header.Grids}</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to={"/event/" + url.eventID + "/results"}
                className={s["menu-item"]}
                title={Text.Header.Results}
              >
                <span className={s.icon}>
                  <i className="fas fa-medal"></i>
                </span>{" "}
                <span className={s.text}>{Text.Header.Results}</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/event/" + url.eventID + "/resultteams"}
                className={s["menu-item"]}
                title={Text.Header.TeamResults}
              >
                <span className={s.icon}>
                  <i className="fas fa-people-group"></i>
                </span>{" "}
                <span className={s.text}>{Text.Header.TeamResults}</span>
              </NavLink>
            </li>
          </ul>
        </div>
      )}

      <button className={s["language-switcher"]} onClick={() => changeLang()}>
        {!hideMenu ? (getCurrentLanguage() == "ru" ? "EN" : "RU") : "RU/EN"}

        {/* <span className={s.text}>Сменить язык</span> */}
      </button>
    </div>
  );
};

export default HeaderLeft;
