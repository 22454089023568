import { AddBox, Close, Edit } from "@mui/icons-material";
import { Button, Chip, IconButton } from "@mui/material";
import { nanoid } from "nanoid";
import React, { useRef, useState } from "react";
import getAgeRangeString from "../../helpers/getAgeRangeString";
import getWeightRangeString from "../../helpers/getWeightRangeString";
import useModalStatus_deepCopy from "../../hooks/useModalStatus_deepCopy";
import Confirmation from "../Confirmation/Confirmation";
import CreateRange from "../CreateRange/CreateRange";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import Loading from "../Loading/Loading";
import CustomSwitch from "../ui/CustomSwitch/CustomSwitch";
import s from "./TemplateModal.module.sass";

const TemplateModal = ({
  isFetching,
  onCancel,
  isEditable,
  categories,
  updateAgeById,
  addWeight,
  updateWeight,
  deleteWeight,
  addCategory,
  deleteCategory,
  setAbsolute,
  saveTemplate,
  isAgeSystem,
  handleChangeAgeSystem,
  onConfirmSelection,
  onSelectTemplate,
  onDeselectTemplate,
  selectedTemplatesId,
}) => {
  const [isModalOpen, setModalOpen, resetModalOpen] = useModalStatus_deepCopy({
    age: false,
    weight: false,
  });
  const [defaults, setDefaults] = useState({
    from: null,
    to: null,
  });
  const ageIdRef = useRef(null);
  const weightIdParamsRef = useRef({
    categoryId: null,
    weightId: null,
    gender: null,
  });

  // * template
  const handleSave = () => {
    saveTemplate();
  };

  const handleConfirmSelection = () => {
    onConfirmSelection();
  };

  // * category
  const handleDeleteCategory = ({ categoryId }) => {
    return () => {
      deleteCategory({ categoryId });
    };
  };

  const handleAddCategory = () => {
    addCategory();
  };

  const handleAbsolute = ({ categoryId, gender }) => {
    return (e) => {
      setAbsolute({ categoryId, gender, status: e.target.checked });
    };
  };

  const handleSelectTemplate = ({ categoryId }) => {
    return (e) => {
      if (e.target.checked) onSelectTemplate({ categoryId });
      else onDeselectTemplate({ categoryId });
    };
  };

  // * age
  const handleOpenAge = ({ categoryId, from, to }) => {
    return () => {
      ageIdRef.current = categoryId;
      setDefaults({ from, to });
      setModalOpen({ age: true });
    };
  };

  const handleCloseAge = () => {
    setModalOpen({ age: false });
    ageIdRef.current = null;
    setDefaults({ from: null, to: null });
  };

  const handleSetAge = ({ from, to }) => {
    updateAgeById({ categoryId: ageIdRef.current, from, to });
  };

  // * weight
  const handleOpenWeight = ({ categoryId, weightId, gender, from, to }) => {
    return () => {
      weightIdParamsRef.current = {
        categoryId,
        weightId,
        gender,
      };
      setDefaults({ from, to });
      setModalOpen({ weight: true });
    };
  };

  const handleCloseWeigh = () => {
    setModalOpen({ weight: false });
    weightIdParamsRef.current = {
      categoryId: null,
      weightId: null,
      gender: null,
    };
    setDefaults({ from: null, to: null });
  };

  const handleSetWeight = ({ from, to }) => {
    if (weightIdParamsRef.current.weightId === null) {
      const { categoryId, gender } = weightIdParamsRef.current;
      addWeight({ categoryId, gender, from, to });
    } else {
      updateWeight({ ...weightIdParamsRef.current, from, to });
    }
  };

  const handleAddWeight = ({ categoryId, gender }) => {
    return () => {
      weightIdParamsRef.current = {
        categoryId,
        weightId: null,
        gender,
      };
      setModalOpen({ weight: true });
    };
  };

  const handleDeleteWeight = ({ categoryId, gender, weightId }) => {
    return () => {
      deleteWeight({ categoryId, gender, weightId });
    };
  };

  // * helpers
  const handleCancel = () => {
    onCancel();
    resetModalOpen();
  };

  return (
    <>
      <Loading isLoading={isFetching} />
      <div className={s.wrapper}>
        {categories.length !== 0 && (
          <CustomSwitch
            label={isAgeSystem ? "Возраст" : "Год рождения"}
            onChange={handleChangeAgeSystem}
            checked={isAgeSystem}
          />
        )}
        <div className={s.categoryWrapper}>
          {categories.length === 0 && <h3>Нет шаблонов</h3>}
          {categories.map((category) => (
            <div
              key={nanoid()}
              className={s.category}
              style={
                !isAgeSystem
                  ? { gridTemplateColumns: "48px minmax(200px, auto) 1fr" }
                  : {}
              }
            >
              {isEditable && (
                <IconButton
                  className={s.category__delete}
                  onClick={handleDeleteCategory({
                    categoryId: category.id,
                  })}
                >
                  <Close className={s.deleteIcon} />
                </IconButton>
              )}
              {!isEditable && (
                <CustomCheckbox
                  onChange={handleSelectTemplate({ categoryId: category.id })}
                  checked={selectedTemplatesId.includes(category.id)}
                />
              )}
              <div className={s.ageBlock}>
                <span className={s.ageBlock__age}>
                  {getAgeRangeString(category.age)}
                </span>
                {isEditable && (
                  <IconButton
                    onClick={handleOpenAge({
                      categoryId: category.id,
                      from: category.age.from,
                      to: category.age.to,
                    })}
                  >
                    <Edit />
                  </IconButton>
                )}
              </div>
              <div className={s.weights}>
                {["male", "female"].map((gender) => (
                  <div key={nanoid()} className={s.weights__set}>
                    <div className={s.weights__weightAmount}>
                      {gender === "male" ? "М" : "Ж"}
                    </div>
                    {category.weights[gender].map((weight) => (
                      <Chip
                        key={weight.id}
                        onClick={handleOpenWeight({
                          categoryId: category.id,
                          gender: gender,
                          from: weight.from,
                          to: weight.to,
                          weightId: weight.id,
                        })}
                        onDelete={
                          isEditable &&
                          handleDeleteWeight({
                            categoryId: category.id,
                            gender: gender,
                            weightId: weight.id,
                          })
                        }
                        label={getWeightRangeString(weight)}
                        className={s.chip}
                      />
                    ))}
                    {isEditable && (
                      <>
                        <IconButton
                          onClick={handleAddWeight({
                            categoryId: category.id,
                            gender,
                          })}
                        >
                          <AddBox />
                        </IconButton>
                        <CustomCheckbox
                          label={"Абсолютка"}
                          onChange={handleAbsolute({
                            categoryId: category.id,
                            gender: gender,
                          })}
                          defaultChecked={
                            category.weights[gender + "IsAbsolute"]
                          }
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {!isEditable && (
          <div className={s.actions}>
            <Button variant="contained" onClick={handleCancel}>
              Отмена
            </Button>
            <Button variant="contained" onClick={handleConfirmSelection}>
              Сохранить
            </Button>
          </div>
        )}

        {isEditable && (
          <>
            <Button
              className={s.addButton}
              color="secondary"
              onClick={handleAddCategory}
            >
              Добавить
            </Button>
            <div className={s.actions}>
              <Button variant="contained" onClick={handleCancel}>
                Отмена
              </Button>
              <Button variant="contained" onClick={handleSave}>
                Сохранить
              </Button>
            </div>

            <CreateRange
              title={"Возрастной диапазон"}
              isOpen={isModalOpen.age}
              onClose={handleCloseAge}
              onSet={handleSetAge}
              defaultRange={defaults}
              isReversed={!isAgeSystem}
            />

            <CreateRange
              title={"Весовой диапазон"}
              isOpen={isModalOpen.weight}
              onClose={handleCloseWeigh}
              onSet={handleSetWeight}
              defaultRange={defaults}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TemplateModal;
