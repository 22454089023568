import { Text, selectText } from "./language/lang";

export const ROOT_URL = "https://pvkgym.ru/fightevent";

export const Role = {
  TRAINER: "trainer",
  SPORTSMAN: "sportsman",
  DIRECTOR: "director",
};

export const EventRegistrationStatus = {
  ALL: 0,
  MEDICAL: 1,
  INSURANCE: 2,
  NONE: 3,
  WEIGHTED: 4,
  PAID: 5,
  UNPAID: 6,
  BAD_MEDICAL: -1,
  BAD_INSURANCE: -2,
  OUT_OF_CATEGORY: -3,
};
export const registrationStatus = {
  3: "Оплачен",
  4: "Взвешен",
  6: "Не оплачен",
};

export const DiscountType = {
  PRE_REGISTRATION: "preRegistration",
  MULTI: "multi",
};

export const EventUserStatusDescription = {
  0: "Ничего не проверено, но турнир обязывает отправку медсправок и страховок",
  1: "Проверена страховка или не предусмотрена турниром, но медсправка не проверена и предусмотрена турниром",
  2: "Проверена медсправка или не предусмотрена турниром, но страховка не проверена и предусмотрена турниром",
  3: "Проверены и медсправка, и страховка или не предусмотрены турниром или проверено что-то одно, а другое не предусмотрено турниром",
  4: "Взвешен",
  5: "Взвешен и документы проверены",
  6: "Не оплачен",
  "-1": "Недействительная медсправка или плохое качество отправленного файла! Нужна повторная регистрация",
  "-2": "Недействительная страховка или плохое качество отправленного файла!",
  "-3": "Боец не попал в категорию. Ждем участия на следующем турнире",
};

export const EventUserConfirmMode = {
  MEDICAL: "medical",
  INSURANCE: "insurance",
  WEIGHT: "weight",
  PAYMENT: "payment",
};

export const FightEndReasons = [
  { name: "PTS Победа по очкам" },
  {
    name: "SU Сдача (болевой, удушающий)",
    items: [
      "SA Болевой на руку",
      "SL Болевой на ногу",
      "SC Удушающий",
      "SO Другое",
    ],
  },
  { name: "WO Неявка, WO(nw) Неявка (не взвешен)" },
  { name: "TKO Явное преимущество" },
  {
    name: "TD Снятие врачом",
    items: ["TDF Снятие врачом c поединка", "TDC Снятие врачом с соревнований"],
  },
  { name: "DQF Дисквалификация c поединка" },
  { name: "DQC Дисквалификация с соревнований" },
  { name: "TD-2 Снятие обоих спортсменов" },
  { name: "KO Нокаут, Удушение с потерей сознания" },
];

export const FetchResult = {
  BAD: 0,
  GOOD: 1,
  NOT_SET: 2,
};

export const Masters = {
  0: Text.Masters.All,
  1: Text.Masters.Newcomers,
  2: Text.Masters.Beginners,
  3: Text.Masters.Skilled,
  4: Text.Masters.Experts,
  5: Text.Masters.Masters,
};

export const Belts = selectText([
  [
    "Без пояса",
    "Белый пояс",
    "Серый пояс",
    "Желтый пояс",
    "Оранжевый пояс",
    "Зеленый пояс",
    "Синий пояс",
    "Пурпурный пояс",
    "Коричневый пояс",
    "Черный пояс",
    "Черный пояс 1 дан",
    "Черный пояс 2 дан",
    "Черный пояс 3 дан",
    "Черный пояс 4 дан",
    "Черный пояс 5 дан",
    "Черный пояс 6 дан",
    "Черный пояс 7 дан",
    "Черный пояс 8 дан",
    "Черный пояс 9 дан",
    "Черный пояс 10 дан",
  ],
  [
    "Without belt",
    "White belt",
    "Gray belt",
    "Yellow belt",
    "Orange belt",
    "Green belt",
    "Blue belt",
    "Purple belt",
    "Brown belt",
    "Black belt",
    "Black belt 1st dan",
    "Black belt 2nd dan",
    "Black belt 3 dan",
    "Black belt 4th dan",
    "Black belt 5th dan",
    "Black belt 6 dan",
    "Black belt 7th dan",
    "Black belt 8 dan",
    "Black belt 9 dan",
    "Black belt 10th dan",
  ],
]);

export const SportsCategories = selectText([
  [
    "Без разряда",
    "3 детский разряд",
    "2 детский разряд",
    "1 детский разряд",
    "3 юношеский разряд",
    "2 юношеский разряд",
    "1 юношеский разряд",
    "3 взрослый разряд",
    "2 взрослый разряд",
    "1 взрослый разряд",
    "Кандидат в мастера спорта (КМС)",
    "Мастер спорта (МС)",
    "Мастер спорта международного класса (МСМК)",
    "Заслуженный мастер спорта (ЗМС)",
  ],
  [
    "No discharge",
    "3 children's category",
    "2 children's category",
    "1 children's category",
    "3 youth category",
    "2 youth category",
    "1 youth category",
    "3 adult category",
    "2 adult category",
    "1 adult category",
    "Candidate Master of Sports (CMS)",
    "Master of Sports (MS)",
    "International Master of Sports (MSMK)",
    "Honored Master of Sports (HMS)",
  ],
]);

export const EventType = selectText([
  [
    "Клубный турнир",
    "Кубок клуба",
    "Чемпионат и первенство клуба",
    "Городской турнир",
    "Кубок города",
    "Чемпионат и первенство города",
    "Областной турнир",
    "Кубок области",
    "Чемпионат и первенство области",
    "Межрегиональный турнир",
    "Кубок округа",
    "Чемпионат и первенство округа",
    "Всероссийский турнир",
    "Кубок России",
    "Чемпионат и первенство России",
    "Международный турнир",
    "Кубок континента",
    "Чемпионат и первенство континента",
    "Кубок мира",
    "Чемпионат и первенство мира",
  ],
  [],
]);

export const EventSystem = {
  OLYMPLIC: "Олимпийская",
  CIRCLE: "Круговая",
  MIXED: "Смешанная",
};

export const EventPaths = {
  INFO: "",
  CATEGORIES: "categories",
  SCHEDULE: "schedule",
  PARTICIPANTS: "unr34_12r",
  FIGHTS: "f356n_ts",
  SETTINGS: "settings",
  BASKETS: "b78h_skt",
  RESULTS: "results",
  RESULTTEAMS: "resultteams",
};

export const CellTypes = {
  DAY: "day",
  SEPARATION: "separation",
  INTERVAL: "interval",
  PLATFORM: "platform",
  STAGE: "stage",
};

export const StageType = {
  All: "all",
  Semifinal: "semifinal",
  Final1: "final1",
  Final3: "final3",
};

export const EventSections = {
  INFO: "ОБЩЕЕ",
  CATEGORIES: "КАТЕГОРИИ",
  SCHEDULE: "РАСПИСАНИЕ",
  PARTICIPANTS: "УЧАСТНИКИ",
  FIGHTS: "ПОЕДИНКИ",
  SETTINGS: "НАСТРОЙКИ",
  BASKETS: "СЕТКИ",
  RESULTS: "РЕЗУЛЬТАТЫ",
};
