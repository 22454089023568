import {
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import { Box } from "@mui/system";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { Masters, Belts } from "../../constants";
import getWeightRangeString from "../../helpers/getWeightRangeString";
import CustomTabPanel from "../CustomTabPanel/CustomTabPanel";
import s from "./EventCategory.module.scss";
import { Text } from "../../language/lang";

const EventCategory = ({ disciplines }) => {
  const [currentIndex, setIndex] = useState(0);
  const handleChangeIndex = (event, newIndex) => {
    setIndex(newIndex);
  };
  const [gender, setGender] = useState(false);
  const masterJson = JSON.parse(
    '{"3 детский разряд":"3 ДР","2 детский разряд":"2 ДР","1 детский разряд":"1 ДР","3 юношеский разряд":"3 ЮР","2 юношеский разряд":"2 ЮР","1 юношеский разряд":"1 ЮР","3 взрослый разряд":"3 ВР","2 взрослый разряд":"2 ВР","1 взрослый разряд":"1 ВР","Кандидат в мастера спорта (КМС)":"КМС","Мастер спорта (МС)":"МС","Мастер спорта международного класса (МСМК)":"МСМК","Заслуженный мастер спорта (ЗМС)":"ЗМС","Без пояса":"Без.П","Белый пояс":"Бел.П","Без разряда":"БезР","Серый пояс":"Сер.П","Желтый пояс":"Жел.П","Оранжевый пояс":"Ор.П","Синий пояс":"Син.П","Зеленый пояс":"Зел.П","Пурпурный пояс":"Пур.П","Коричневый пояс":"Кор.П","Черный пояс":"Чер.П","Черный пояс 1 дан":"ЧП 1Д","Черный пояс 2 дан":"ЧП 2Д","Черный пояс 3 дан":"ЧП 3Д","Черный пояс 4 дан":"ЧП 4Д","Черный пояс 5 дан":"ЧП 5Д","Черный пояс 6 дан":"ЧП 6Д","Черный пояс 7 дан":"ЧП 7Д","Черный пояс 8 дан":"ЧП 8Д","Черный пояс 9 дан":"ЧП 9Д","Черный пояс 10 дан":"ЧП 10Д"}'
  );
  return (
    <div className={s.wrapper}>
      <div>
        <h3 className={s.title}>{Text.EventCategories.Title}</h3>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
            gap: "10px",
          }}
        >
          <div className={s["toggle-container"]}>
            <div className={s.toggle14}>
              <input
                type="checkbox"
                id="toggle14"
                checked={gender}
                onChange={() => setGender(!gender)} // Обработка изменения состояния
              />
              <label htmlFor="toggle14">
                <span></span>
              </label>
            </div>
          </div>
          <Tabs
            value={currentIndex}
            onChange={handleChangeIndex}
            variant="scrollable"
            scrollButtons="auto"
          >
            {disciplines.map((discipline, i) => (
              <Tab key={nanoid()} label={discipline.name} value={i} />
            ))}
          </Tabs>
        </Box>

        {disciplines.map((discipline, i) => {
          const masters = discipline.masters
            .map((master) => master.master)
            .sort();

          return (
            <CustomTabPanel
              key={nanoid()}
              currentIndex={currentIndex}
              index={i}
            >
              {/* <Box sx={{ display: "flex", gap: "5px", margin: "10px 0 10px 0" }}>
              {discipline.masters.map((master) => (
                <Box
                  sx={{
                    border: "2px solid #fad744",
                    borderRadius: "20px",
                    padding: "10px",
                    minHeight: "100px",
                    width: "450px",
                  }}
                >
                  <Box className={s.master__name}>{Masters[master.master]}</Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {" "}
                    {master.limits.map((lim, j) => (
                      <div key={nanoid()} className={s.master}>
                        <Box sx={{ fontSize: "12px" }}>{masterJson[lim]}</Box>
                      </div>
                    ))}{" "}
                  </Box>
                </Box>
              ))}
            </Box> */}

              <div
                id="tab1"
                className={`${s["category-grid"]} ${s["active"]}`}
                data-gender="male"
              >
                {masters.map((master) => {
                  return (
                    <div className={s.row}>
                      {discipline.rows.map((row) => {
                        return (
                          <div
                            className={`${s.category} ${
                              master == 3 ? s.category_red : ""
                            }`}
                          >
                            <h2>
                              {row.age.to === -1
                                ? "18 лет и старше"
                                : row.age.from + "-" + row.age.to + " лет"}{" "}
                              <span className={s.level}>{Masters[master]}</span>
                            </h2>
                            <div className={s.description}>{Belts[master]}</div>
                            <div className={s.weights}>
                              {gender
                                ? row.weightCategories.female.map(
                                    (m, index) =>
                                      m.to != -1 && (
                                        <div>
                                          {row.weightCategories.female.length -
                                            2 ===
                                          index
                                            ? m.to + "+"
                                            : -m.to}
                                        </div>
                                      )
                                  )
                                : row.weightCategories.male.map(
                                    (m, index) =>
                                      m.to != -1 && (
                                        <div>
                                          {row.weightCategories.male.length -
                                            2 ===
                                          index
                                            ? m.to + "+"
                                            : -m.to}
                                        </div>
                                      )
                                  )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>

              {/* <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow className={s.table__head}>
                    <TableCell>{Text.EventCategories.AgeYear}</TableCell>
                    <TableCell>{Text.EventCategories.MenWeight}</TableCell>
                    <TableCell>{Text.EventCategories.WomenWeight}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {discipline.rows.map((row) => (
                    <TableRow key={nanoid()}>
                      <TableCell className={s.table__age}>
                        {getWeightRangeString(row.age)}
                      </TableCell>
                      <TableCell>
                        {row.weightCategories.male.map((weight, i) =>
                          weight.to === -1 ? (
                            <span key={nanoid()}>{weight.from}+</span>
                          ) : (
                            <span key={nanoid()}>
                              {weight.from}-{weight.to}
                              {i !== row.weightCategories?.male.length - 1
                                ? ","
                                : " " + Text.Base.Kg}
                              &nbsp;&nbsp;
                            </span>
                          )
                        )}
                      </TableCell>
                      <TableCell>
                        {row.weightCategories.female.map((weight, i) =>
                          weight.to === -1 ? (
                            <span key={nanoid()}>{weight.from}+</span>
                          ) : (
                            <span key={nanoid()}>
                              {weight.from}-{weight.to}
                              {i !== row.weightCategories?.female.length - 1
                                ? ","
                                : " " + Text.Base.Kg}
                              &nbsp;&nbsp;
                            </span>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
            </CustomTabPanel>
          );
        })}
      </div>
    </div>
  );
};

export default EventCategory;
