import { fightAPI } from "../../../api/fightAPI"
import { FetchResult } from "../../../constants"
import { setBasket, setFetching, setFightsByDays } from "./fight"


export const sendFight = ({ id, fight_id, result }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
            isFightUpdated: false
        }))
        fightAPI.sendFight({ id, fight_id, result }).then(response => {
            dispatch(setFetching({
                isLoading: false,
                isFightUpdated: true,
                result: response.ok ? FetchResult.GOOD : FetchResult.BAD
            }))
        })
    }
}

export const updateFightResult = ({ id, fight_id, basket, result }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
            isFightUpdated: false
        }))
        fightAPI.updateFightResult({ id, fight_id, basket, result }).then(response => {
            dispatch(setFetching({
                isLoading: false,
                isFightUpdated: true,
                result: response.ok ? FetchResult.GOOD : FetchResult.BAD
            }))
        })
    }
}

export const getFightsOnPlatform = ({ id }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
            result: FetchResult.NOT_SET
        }))
        fightAPI.getFightsOnPlatform({ id }).then(response => {
            dispatch(setFightsByDays(response.data))
            dispatch(setFetching({
                isLoading: false
            }))
        })
    }
}

export const getBasket = ({ id, basket }) => {
    return (dispatch) => {
        dispatch(setFetching({
            isLoading: true,
            isBasket: true
        }))
        fightAPI.getBasket({ id, basket }).then(response => {
            dispatch(setBasket(response.data))
            dispatch(setFetching({
                isLoading: false,
                isBasket: false,
                result: FetchResult.NOT_SET
            }))
        })
    }
}