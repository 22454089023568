import TeamCard from "./TeamCard";
import {
  getResultsByClubs,
  getWinners,
} from "../../redux/reducers/event/eventThunks.ts";
import { getEventState } from "../../redux/selectors/getEventState";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const CommandTeams = () => {
  const dispatch = useDispatch();
  const { eventID } = useParams();
  const { resultsByClubs } = useSelector(getEventState);

  const sortedResults = !resultsByClubs
    ? []
    : [...resultsByClubs].sort((a, b) => {
        return b.score - a.score;
      });
  useEffect(() => {
    dispatch(getResultsByClubs({ id: eventID }));
  }, []);

  return (
    <div>
      {sortedResults?.map((team, index) => (
        <TeamCard key={team.id} team={team} index={index} />
      ))}
    </div>
  );
};

export default CommandTeams;
