import {
  Box,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import useModalStatus_deepCopy from "../../hooks/useModalStatus_deepCopy";
import BasketWinners from "../BasketWinners/BasketWinners2";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CustomTabPanel from "../CustomTabPanel/CustomTabPanel";
import FightsDiagram from "../FightsDiagram/FightsDiagram";
import BasketFilter from "../filters/BasketFilterBlue";
import ResultsFilter from "../filters/ResultsFilter";
import DownloadIcon from "@mui/icons-material/Download";
import s from "./EventResults.module.sass";
import Loading from "../Loading/Loading";
import { Text } from "../../language/lang";
import { getEventState } from "../../redux/selectors/getEventState";
import { useSelector } from "react-redux";
const WinnersTable = ({ winners = [], download }) => {
  console.log(winners);
  return (
    <TableContainer>
      <Table
        size="small"
        className={s.table}
        sx={{ border: "1px solid black" }}
      >
        <TableHead>
          <TableRow>
            <TableCell>{Text.EventResults.Place}</TableCell>
            <TableCell>{Text.EventResults.FIO}</TableCell>
            <TableCell>{Text.EventResults.Category}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {winners.map((user, i) => (
            <TableRow key={user.basket + i + user.id}>
              <TableCell>{user.place}</TableCell>
              <TableCell>
                <span
                  onClick={download({ user_id: +user.id, basket: user.basket })}
                  className={s.diploma}
                >
                  {user.fio}&nbsp;
                  <span className={s.diplomaDownload}>
                    <DownloadIcon />
                  </span>
                </span>
              </TableCell>
              <TableCell>{user.basket}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const EventResults = ({
  updateCurrentDiscipline,
  resultsByBaskets = [],
  resultsByClubs = [],
  winners = [],
  isWinnersTabEnabled,
  download,
  fetchingDoc,
  isFetching,
}) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [isModalOpen, setModalOpen, resetModalOpen] = useModalStatus_deepCopy({
    diagram: false,
  });
  const { event } = useSelector(getEventState);
  const [flagDownloadAwarding, setFlagDownloadAwarding] = useState(false);

  const [isHideEmpty, setHideEmpty] = useState(true);
  const [basketData, setBasketData] = useState({
    name: null,
    fights: [],
    system: null,
  });
  const sortedResults = !resultsByClubs
    ? []
    : [...resultsByClubs].sort((a, b) => {
        return b.score - a.score;
      });
  useEffect(() => {
    let flagDownloadAwarding = false;
    if (event.settings != undefined) {
      flagDownloadAwarding = JSON.parse(event.settings)?.winners ? true : false;
    }

    setFlagDownloadAwarding(flagDownloadAwarding);
  }, [event]);
  const handleCloseModal = () => {
    resetModalOpen();
  };

  const handleChangeIndex = (event, index) => {
    setCurrentIndex(index);
  };

  const provideBasketData = ({ name, fights, system }) => {
    return (e) => {
      setBasketData({ name, fights, system });
      setModalOpen({ diagram: true });
    };
  };

  const handleHideEmpty = (e) => {
    setHideEmpty(e.target.checked);
  };

  return (
    <div>
      <Loading isLoading={fetchingDoc.isLoading || isFetching} />
      <>
        <div className={s.resultsWrapper}>
          {/* {currentIndex === 1 && (
            <div className={`${s.filter} ${s.filter_desktop}`}>
              <BasketFilter isExpanded />
            </div>
          )}
          {currentIndex === 0 && (
            <div className={`${s.filter} ${s.filter_desktop}`}>
              <ResultsFilter isExpanded />
            </div>
          )} */}

          <div className={s.results}>
            {/* <CustomTabPanel currentIndex={currentIndex} index={0}>
              <div className={`${s.filter} ${s.filter_mobile}`}>
                <ResultsFilter />
              </div>
              <br />
              <ResultsTable results={sortedResults} />
            </CustomTabPanel> */}
            <CustomTabPanel currentIndex={currentIndex} index={1}>
              {/* <div className={`${s.filter} ${s.filter_mobile}`}>
                <BasketFilter isHiddenFields={{ discipline: true }} />
              </div> */}

              <CustomCheckbox
                label={Text.EventResults.HideEmptyGrids}
                onChange={handleHideEmpty}
                defaultChecked
              />
              {/* isExpanded  это открыть фильтр по умолчанию*/}
              <BasketFilter />
              <div className={s.baskets}>
                {resultsByBaskets.map((basket) => {
                  const baskets = basket.baskets;
                  return baskets.map((bas) => {
                    if (bas.results.length === 0 && isHideEmpty) return null;
                    return (
                      <div key={nanoid()}>
                        <BasketWinners
                          basket={bas}
                          click={provideBasketData({
                            name: bas.name,
                            fights: bas.fights,
                            system: bas.system,
                          })}
                          flagDownloadAwarding={flagDownloadAwarding}
                          download={download}
                        />
                      </div>
                    );
                  });
                })}
              </div>

              <FightsDiagram
                fights={basketData.fights}
                name={basketData.name}
                system={basketData.system}
                isOpen={isModalOpen.diagram}
                onClose={handleCloseModal}
              />
            </CustomTabPanel>
            <CustomTabPanel currentIndex={currentIndex} index={2}>
              <br />
              <div>
                {winners?.length === 0 && <b>Нет наград</b>}
                {winners?.length !== 0 && (
                  <WinnersTable winners={winners} download={download} />
                )}
              </div>
            </CustomTabPanel>
          </div>
        </div>
      </>
    </div>
  );
};

export default EventResults;
