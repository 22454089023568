import { userAPI } from "../../api/userAPI";
import { setUserID, setIsLogoutRedirect } from "./auth.ts";
import { setClubID } from "./club.ts";
import * as auth from "./auth.ts";
import { deleteCookie } from "../../AppExtComponents.ts";

// * ===================================== TYPES =====================================
interface State {
  isFetching: {
    init: boolean;
    gettingUser: boolean;
    image: boolean;
    password: boolean;
    info: boolean;
    trainer: boolean;
    club: boolean;
    docs: boolean;
  };
  isEditing: boolean;
  response: {
    show: boolean;
    ok: boolean;
    text: string;
  };
  userData: any;
  forceRerender: boolean;
  isAdmin: boolean;
  isShowEvents: boolean;
  isDocsUpdated: boolean;
}

interface Action {
  type: string;
  isEditing?: boolean;
  response?: Response;
  userData?: any;
  isFetching?: Fetching;
  forceRerender?: boolean;
  isAdmin?: boolean;
  isShowEvents?: boolean;
  isDocsUpdated?: boolean;
}
interface Fetching {
  init?: boolean;
  gettingUser?: boolean;
  image?: boolean;
  password?: boolean;
  info?: boolean;
  trainer?: boolean;
  club?: boolean;
  docs?: boolean;
}
interface Response {
  show: boolean;
  ok: boolean;
  text: string;
}

// * ===================================== ACTIONS =====================================

const SET_FETCHING = "SET_FETCHING_user";
const SET_RESPONSE = "SET_RESPONSE_user";
const SET_USER_DATA = "SET_USER_DATA";
const SET_EDITING = "SET_EDITING";
const SET_IS_ADMIN = "SET_IS_ADMIN";
const SET_FORCE_RERENDER = "SET_FORCE_RERENDER";
const SHOW_EVENTS = "user/SHOW_EVENTS";
const SET_IS_DOCS_UPDATED = "user/SET_IS_DOCS_UPDATED";

// * ===================================== REDUCER ==========================================

let initialState: State = {
  isEditing: false,
  response: {
    show: false,
    ok: false,
    text: "",
  },
  userData: {},
  isFetching: {
    init: true,
    gettingUser: false,
    image: false,
    password: false,
    info: false,
    trainer: false,
    club: false,
    docs: false,
  },
  forceRerender: false,
  isAdmin: false,
  isShowEvents: false,
  isDocsUpdated: true,
};

export default function userReducer(
  state = initialState,
  action: Action
): State {
  switch (action.type) {
    case SET_FETCHING:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          ...action.isFetching,
        },
      };
    case SET_RESPONSE:
      return {
        ...state,
        response: action.response!,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.userData,
      };
    case SET_EDITING:
      return {
        ...state,
        isEditing: action.isEditing!,
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin!,
      };
    case SET_FORCE_RERENDER:
      return {
        ...state,
        forceRerender: action.forceRerender!,
      };
    case SHOW_EVENTS:
      return {
        ...state,
        isShowEvents: action.isShowEvents!,
      };
    case SET_IS_DOCS_UPDATED:
      return {
        ...state,
        isDocsUpdated: action.isDocsUpdated!,
      };
    default:
      return state;
  }
}

// * ===================================== ACTION CREATORS =====================================

export const setFetching = (isFetching: Fetching): Action => ({
  type: SET_FETCHING,
  isFetching: isFetching,
});
export const setResponse = (response: Response): Action => ({
  type: SET_RESPONSE,
  response: { show: response.show, ok: response.ok, text: response.text },
});
export const resetResponse = () => ({
  type: SET_RESPONSE,
  response: { show: false, ok: true, text: "" },
});
export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  userData: userData,
});
export const setEditing = (status: boolean) => ({
  type: SET_EDITING,
  isEditing: status,
});
export const setIsAdmin = (status: boolean) => ({
  type: SET_IS_ADMIN,
  isAdmin: status,
});
export const showEvents = (status: boolean) => ({
  type: SHOW_EVENTS,
  isShowEvents: status,
});
export const setForceRerender = (status: boolean) => ({
  type: SET_FORCE_RERENDER,
  forceRerender: status,
});
export const setDocsUpdated = (status: boolean) => ({
  type: SET_IS_DOCS_UPDATED,
  isDocsUpdated: status,
});

// * ===================================== HELPERS ==============================================

// * ===================================== THUNKS ==============================================

export const getUser = ({ query, query_name }) => {
  return (dispatch) => {
    dispatch(setFetching({ gettingUser: true }));
    userAPI.getUser({ query, query_name }).then((data) => {
      dispatch(setFetching({ gettingUser: false }));
      dispatch(setFetching({ init: false }));
      dispatch(setUserData(data.data));
      dispatch(setClubID(data.data.club ? JSON.parse(data.data.club).id : -1));
      localStorage.setItem("user", JSON.stringify(data.data));
    });
  };
};

export const setNewPassword = ({ id, old_password, new_password }) => {
  return (dispatch) => {
    dispatch(setFetching({ password: true }));
    userAPI.checkPassword({ id, old_password }).then((data) => {
      if (Boolean(data.ok) === false) {
        dispatch(
          setResponse({
            show: true,
            ok: Boolean(data.ok),
            text: data.description,
          })
        );
      }
      userAPI.updatePassword({ id, new_password }).then((data) => {
        dispatch(setFetching({ password: false }));
        dispatch(
          setResponse({
            show: true,
            ok: Boolean(data.ok),
            text: data.description,
          })
        );
      });
    });
  };
};

export const setPasswordFromAdmin = ({ id, new_password }) => {
  return (dispatch) => {
    dispatch(setFetching({ password: true }));
    userAPI.updatePassword({ id, new_password }).then((data) => {
      dispatch(setFetching({ password: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const updateImage = ({ id, image }) => {
  return (dispatch) => {
    dispatch(setFetching({ image: true }));
    userAPI.updateImage({ id, image }).then((data) => {
      dispatch(setFetching({ image: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const updateUser = ({ id, data }) => {
  return (dispatch) => {
    dispatch(setFetching({ info: true }));
    userAPI.updateUser({ id, data }).then((data) => {
      dispatch(setFetching({ info: false }));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const requestToTrainer = (data) => {
  return (dispatch) => {
    dispatch(setFetching({ trainer: true }));
    userAPI.requestToTrainer(data).then((data) => {
      dispatch(setFetching({ trainer: false }));
      dispatch(setForceRerender(true));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const addUserToTrainer = ({ trainer_id, user_id }) => {
  return (dispatch) => {
    dispatch(setFetching({ trainer: true }));
    userAPI.addUserToTrainer({ trainer_id, user_id }).then((data) => {
      dispatch(setFetching({ trainer: false }));
      dispatch(setForceRerender(true));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const rejectUser = ({ trainer_id, user_id }) => {
  return (dispatch) => {
    dispatch(setFetching({ trainer: true }));
    userAPI.rejectUser({ trainer_id, user_id }).then((data) => {
      dispatch(setFetching({ trainer: false }));
      dispatch(setForceRerender(true));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const secondConfirmation = ({ userId, files }) => {
  return (dispatch) => {
    dispatch(setFetching({ docs: true }));
    userAPI.secondConfiramtion({ userId, files }).then((data) => {
      dispatch(setFetching({ docs: false }));
      dispatch(setUserData(data.data));
      dispatch(setDocsUpdated(Boolean(data.ok)));
      dispatch(setForceRerender(true));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const deleteUserFromTrainer = ({ trainer_id, user_id }) => {
  return (dispatch) => {
    dispatch(setFetching({ trainer: true }));
    userAPI.deleteUserFromTrainer({ trainer_id, user_id }).then((data) => {
      dispatch(setFetching({ trainer: false }));
      dispatch(setForceRerender(true));
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};

export const deleteUser = ({ id }) => {
  return (dispatch) => {
    dispatch(setFetching({ init: true }));
    userAPI.deleteUser({ id }).then((data) => {
      dispatch(setFetching({ init: false }));
      dispatch(setUserData({}));
      dispatch(setUserID(-1));
      dispatch(setClubID(-1));
      dispatch(setIsLogoutRedirect(true));
      deleteCookie("user");
      dispatch(
        auth.setResponse({
          show: true,
          ok: Boolean(data.ok),
          text: data.description,
        })
      );
    });
  };
};
