import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEvents, resetEvents } from "../../redux/reducers/selection.ts";
import GridCompetitions from "./GridCompetitions";
import { CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import s from "./GridCompetitions.module.sass";
import { Text } from "../../language/lang.js";

export const GridCompetitionsCC = (props) => {
  const PAGE_SIZE = 12;
  const {
    events,
    isFetchingSelection,
    eventsSelectionMode,
    gridCompetitionsFilter,
  } = useSelector((state) => ({
    events: state.selection.events,
    eventsSelectionMode: state.selection.eventsSelectionMode,
    isFetchingSelection: state.selection.isFetching,
    gridCompetitionsFilter: state.event.gridCompetitionsFilter,
  }));

  const dispatch = useDispatch();
  const currentPage = useRef(1);
  const getEventsByTime = ({ count, page }) => {
    dispatch(
      getEvents({
        count,
        page,
        time:
          eventsSelectionMode !== ""
            ? eventsSelectionMode
            : gridCompetitionsFilter,
      })
    );
  };

  // useEffect(() => {
  //   getEventsByTime({ count: PAGE_SIZE, page: 1 });
  // }, []);
  //Фильтр рендерит первым поэтому обновляет два раза с этой срочкой кода из за чего Events дублируются

  useEffect(() => {
    return () => {
      dispatch(resetEvents());
    };
  }, []);

  const loadMore = () => {
    currentPage.current++;
    getEventsByTime({ count: PAGE_SIZE, page: currentPage.current });
  };

  return (
    <>
      {isFetchingSelection.events && events.length === 0 && (
        <div style={{ width: "100%", display: "flex" }}>
          <CircularProgress
            style={{
              margin: "30vh auto",
            }}
          />
        </div>
      )}

      {events.length !== 0 && (
        <div className={s.gridWrapper}>
          <GridCompetitions events={events} />
          <div className={s.loadingButtonWrapper}>
            <LoadingButton
              loading={isFetchingSelection.events}
              onClick={loadMore}
              color={"secondary"}
            >
              {Text.Base.LoadMore}
            </LoadingButton>
          </div>
        </div>
      )}
    </>
  );
};
