import { createTheme } from "@mui/material/styles";

const themeOptions = {
  typography: {
    fontFamily: "Jura, Roboto, sans-serif",
  },
  palette: {
    type: "dark",
    primary: {
      main: "#295f8a",
    },
    secondary: {
      main: "#295f8a",
    },
    background: {
      default: "#f4f4f9",
      paper: "white",
    },
    text: {
      primary: "rgba(0,0,0,1)",
      secondary: "#000000",
      disabled: "rgba(0,0,0,0.38)",
      hint: "rgba(0,0,0,0.38)",
    },
  },
  shape: {
    borderRadius: 15,
  },
  props: {
    MuiAppBar: {
      color: "transparent",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "#1f3b4d",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "#1f3b4d",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0)",
        },
      },
    },
  },
};

export const appTheme = createTheme(themeOptions);
