import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { parseJSON } from "../../helpers/parseJSON";
import { getEventState } from "../../redux/selectors/getEventState";
import getSettingsState from "../../redux/selectors/getSettingsState";
import CustomSelect from "../CustomSelect/CustomSelect";
import Loading from "../Loading/Loading";
import s from "./EventSettings.module.sass";

const Presets = ({ send }) => {
  const { event } = useSelector(getEventState);
  const { fetching } = useSelector(getSettingsState);
  const [eventInfo, setEventInfo] = useState({
    duration: 0,
    dateFrom: 0,
  });
  const settings = parseJSON({ json: event.settings, defaultReturn: {} }).rules;

  useEffect(() => {
    setEventInfo({
      duration: new Date(+event.date_to - +event.date_from).getDate(),
      dateFrom: new Date(+event.date_from),
    });
  }, [event]);

  const handleSend = (e) => {
    e.preventDefault();
    send(e);
  };

  const getDateDays = () => {
    return [...Array(eventInfo.duration).keys()].map((el) => {
      const _date = new Date(eventInfo.dateFrom);
      const _lastDay = eventInfo.dateFrom.getDate();
      _date.setDate(_lastDay + el);
      const _currentDate = _date.toLocaleDateString("ru-RU");
      return { value: _currentDate, name: _currentDate };
    });
  };

  return (
    <>
      <Loading isLoading={fetching.isLoading} />
      <form className={s.presets} onSubmit={handleSend} id="presets-settings">
        <div className={s.presets__grid}>
          <span>Считать земляками по критерию:</span>
          <CustomSelect
            value={""}
            name={"countrymen"}
            items={[
              { name: "Клуб", value: 0 },
              { name: "Город", value: 1 },
              { name: "Регион", value: 2 },
              { name: "Страна", value: 3 },
            ]}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: "16px", alignSelf: "flex-end" }}
        >
          Сохранить
        </Button>
      </form>
    </>
  );
};

export default Presets;
