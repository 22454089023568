import { basketAPI } from "../../../api/basketAPI";
import { FetchResult } from "../../../constants";
import { parseJSON } from "../../../helpers/parseJSON";
import { setResponse } from "../snacks.ts";
import { setFetching, setResultsByBaskets, setRules } from "./basket";

export const getResultsByBaskets = ({ id }) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        isLoading: true,
        result: FetchResult.NOT_SET,
      })
    );
    basketAPI.getResultsByBaskets({ id }).then((response) => {
      dispatch(setResultsByBaskets(response.data));
      dispatch(
        setFetching({
          isLoading: false,
          result: FetchResult.GOOD,
        })
      );
    });
  };
};

export const confirmBaskets = ({ id }) => {
  return (dispatch) => {
    dispatch(
      setFetching({
        isLoading: true,
      })
    );
    basketAPI.confirmBaskets({ id }).then((response) => {
      dispatch(
        setResponse({
          show: true,
          ok: Boolean(response.ok),
          text: response.description,
        })
      );
      dispatch(
        setFetching({
          isLoading: false,
        })
      );
    });
  };
};
