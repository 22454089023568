import React from "react";
import styles from "./BasketWinners.module.scss";

const BasketWinners = ({ basket, click, flagDownloadAwarding, download }) => {
  const result = basket?.results;
  const statsFirst = basket?.results?.first?.stats;
  const statsSecond = basket?.results?.second?.stats;
  const statsThird = basket?.results?.third?.stats;
  const containerDownload = (place) => {
    return (
      flagDownloadAwarding && (
        <i
          onClick={download({
            user_id: +result?.[place]?.id,
            basket: basket.name,
          })}
          className={`fas fa-download ${styles["download-icon"]}`}
          title="Скачать диплом"
        ></i>
      )
    );
  };
  return (
    <div className={styles.discipline}>
      <div className={styles["age-category"]} onClick={click}>
        {basket?.name}
      </div>
      <div className={styles["result-table"]}>
        {result.hasOwnProperty("first") && (
          <div className={`${styles["result-card"]} ${styles.champion}`}>
            <div className={styles["champion-place"]}>Чемпион</div>
            <div className={styles["result-item"]}>
              <div className={styles["place-badge"]}>1</div>
              <div>
                <h3>{result?.first?.fio}</h3>
                <p>
                  {result?.first?.club?.name} {result?.first?.from}
                </p>
              </div>
              {containerDownload("first")}
            </div>
            <div className={styles.stats}>
              <span>
                <i className="fas fa-fist-raised"></i>Бои: {statsFirst?.fights}
              </span>
              <span>
                <i className="fas fa-trophy"></i>Победы: {statsFirst?.wins}
              </span>
              <span>
                <i className="fas fa-star"></i>Очки: {statsFirst?.points}
              </span>
              {statsFirst?.rank.length > 1 && (
                <span>
                  <i className="fas fa-medal"></i>Выполнил: {statsFirst?.rank}
                  разряд
                </span>
              )}
            </div>
          </div>
        )}
        {result.hasOwnProperty("second") && (
          <div className={`${styles["result-card"]} ${styles["second-place"]}`}>
            <div className={styles["champion-place-second"]}>Призер</div>
            <div className={styles["result-item"]}>
              <div className={styles["place-badge"]}>2</div>
              <div>
                <h3>{result?.second?.fio}</h3>
                <p>
                  {" "}
                  {result?.second?.club?.name} {result?.second?.from}
                </p>
              </div>
              {containerDownload("second")}
            </div>
            <div className={styles.stats}>
              <span>
                <i className="fas fa-fist-raised"></i>Бои: {statsSecond?.fights}
              </span>
              <span>
                <i className="fas fa-trophy"></i>Победы: {statsSecond?.wins}
              </span>
              <span>
                <i className="fas fa-star"></i>Очки: {statsSecond?.points}
              </span>
              {statsSecond?.rank.length > 1 && (
                <span>
                  <i className="fas fa-medal"></i>Выполнил: {statsSecond?.rank}
                  разряд
                </span>
              )}
            </div>
          </div>
        )}
        {result.hasOwnProperty("third") && (
          <div className={`${styles["result-card"]} ${styles["third-place"]}`}>
            <div className={styles["champion-place-third"]}>Призер</div>
            <div className={styles["result-item"]}>
              <div className={styles["place-badge"]}>3</div>
              <div>
                <h3>{result?.third?.fio}</h3>
                <p>
                  {result?.third?.club?.name} {result?.third?.from}
                </p>
              </div>
              {containerDownload("third")}
            </div>
            <div className={styles.stats}>
              <span>
                <i className="fas fa-fist-raised"></i>Бои: {statsThird?.fights}
              </span>
              <span>
                <i className="fas fa-trophy"></i>Победы: {statsThird?.wins}
              </span>
              <span>
                <i className="fas fa-star"></i>Очки: {statsThird?.points}
              </span>
              {statsThird?.rank.length > 1 && (
                <span>
                  <i className="fas fa-medal"></i>Выполнил: {statsThird?.rank}
                  разряд
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasketWinners;
