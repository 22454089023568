import { Paper } from "@mui/material";
import { ROOT_URL } from "../../constants";
import s from "./Information.module.sass";

export const Information = (props) => {
  return (
    <div
      className={s.informationPage}
      style={{ backgroundImage: `url('${ROOT_URL}/img/3.jpeg')` }}
    >
      <Paper className={s.wrapper}>
        <h2>
          Сервис проведения мероприятий по единоборствам и боевым искусствам{" "}
          <a
            href="COMBAT ARENA
.ru"
          >
            COMBAT ARENA .ru
          </a>
        </h2>
        <br />
        Самый простой способ организовывать турниры, участвовать в них и следить
        за ними. Мы собрали весь наш опыт участия в соревнованиях, тренерской
        работе и организации турниров по различным видам единоборств. Теперь мы
        стремимся охватить больше видов спорта и стилей боевых искусств с
        помощью нашего интуитивно понятного и инновационного программного
        обеспечения. Система управляет всем процессом, от плавной регистрации
        для одного бойца до массовой регистрации в качестве тренера. COMBAT
        ARENA .ru обрабатывает платежи, настройку сеток, взвешивание и подсчет
        очков во время поединков.
        <div className={s.imgWrapper}>
          <img src={ROOT_URL + "/img/1.jpeg"} alt="" />
          <img src={ROOT_URL + "/img/2.jpeg"} alt="" />
        </div>
        Рейтинг лучших клубов начисляется во время соревнований. Мы надеемся,
        что система облегчит вам задачу как бойцу или тренеру, а также даст
        аудитории хороший опыт при использовании системы через Интернет. Вы
        заинтересованы в использовании COMBAT ARENA .ru для вашей федерации или
        вашего клуба? Свяжитесь с нами, и мы поможем вам организовать ваш
        следующий турнир в кратчайшие сроки.
        {/* <br /><b>Почта: <a href='mailto:COMBAT ARENA
@yandex.ru'>COMBAT ARENA
@yandex.ru</a> к.т.:<a href='tel:+79655257700'>8-965-525-77-00</a></b> */}
        <div className={s.imgWrapper}>
          <img src={ROOT_URL + "/img/4.jpeg"} alt="" />
          <img src={ROOT_URL + "/img/5.jpeg"} alt="" />
        </div>
      </Paper>
    </div>
  );
};
