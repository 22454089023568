import axios from "axios"
import { ROOT_URL } from "../constants"
import { wrapToJWT } from "./wrapToJWT";
import { langWrapper } from "./langWrapper";



const instance = langWrapper(wrapToJWT(axios.create({
    baseURL: ROOT_URL + '/action.php'
})))


export const fightAPI = {
    sendFight({ id, fight_id, result }) {
        return instance.post('/', {
            action: 'updateFight',
            data: {
                id, fight_id, result
            }
        }).then((response) => response.data)
    },

    updateFightResult({ id, fight_id, basket, result }) {
        return instance.post('/', {
            action: 'updateFightResult',
            data: {
                id, fight_id, basket, result
            }
        }).then((response) => response.data)
    },

    getFightsOnPlatform({ id }) {
        return instance.post('/', {
            action: 'getFightsOnPlatform',
            data: {
                id
            }
        }).then((response) => response.data)
    },

    getBasket({ id, basket }) {
        return instance.post('/', {
            action: 'getBasket',
            data: {
                id, basket
            }
        }).then((response) => response.data)
    }
}