import {
  Alert,
  Button,
  Grid,
  IconButton,
  Input,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import s from "./EventSettings.module.sass";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { nanoid } from "nanoid";
import Presets from "./Presets";
import { CellTypes, Masters, StageType } from "../../constants";
import { LoadingButton, TimePicker } from "@mui/lab";
import FileUpload from "../FileUpload/FileUpload";
import Loading from "../Loading/Loading";
import CustomSelect from "../CustomSelect/CustomSelect";
import { zeroPad } from "react-countdown";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import Separation from "./Separation/Separation";
import ControlledModal from "../ControlledModal/ControlledModal";
import { getEventState } from "../../redux/selectors/getEventState";
import { useSelector } from "react-redux";
import { parseJSON } from "../../helpers/parseJSON";

const ExpandButton = ({ onClick, isOpen }) => {
  return (
    <span sx={{ height: 0 }}>
      <IconButton
        onClick={onClick}
        sx={{
          height: 14,
          width: 14,
          border: "1px solid rgba(255,255,255,0.5)",
        }}
      >
        {isOpen ? (
          <RemoveIcon sx={{ fontSize: "16px" }} />
        ) : (
          <AddIcon sx={{ fontSize: "16px" }} />
        )}
      </IconButton>
    </span>
  );
};

const CustomTimeField = ({ defaultValue, onBlur }) => {
  const [value, setValue] = useState(
    !defaultValue
      ? ""
      : zeroPad(defaultValue.split(":")[0]) +
          ":" +
          zeroPad(defaultValue.split(":")[1])
  );

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    onBlur(value);
  };

  return (
    <TextField
      variant="standard"
      value={value}
      type="time"
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

const EventSettings = ({
  stats,
  expandDisciplines,
  expandMasters,
  handleSaveTranslations,
  isLoading,
  isSavingURLs,
  sendDocx,
  eventInfo,
  changeCellValue,
  send,
  handleSetWinnersTab,
  winnersTab,
  platformsNumber,
  platformsURLs,
}) => {
  const { event } = useSelector(getEventState);
  const [file, setFile] = useState(null);
  const [separation, setSeparation] = useState({
    isOpen: false,
    onSet: null,
    days: [],
    defaultValues: {},
  });
  const settings = parseJSON({ json: event.settings, defaultReturn: {} }).rules;

  const handleSendDocx = (e) => {
    e.preventDefault();
    sendDocx(file);
  };

  const handleSetFile = (f) => {
    setFile(f[0]);
  };

  const handleOpenSeparation = ({ onSet, days, defaultValues }) => {
    return () => {
      setSeparation({ isOpen: true, onSet, days, defaultValues });
    };
  };

  const handleCloseSeparation = () => {
    setSeparation({ isOpen: false, onSet: null, days: [], defaultValues: {} });
  };

  const getItems = (cellType) => {
    switch (cellType) {
      case CellTypes.DAY:
        return [...Array(eventInfo.duration).keys()].map((el) => {
          const _date = new Date(eventInfo.dateFrom);
          const _lastDay = eventInfo.dateFrom.getDate();
          _date.setDate(_lastDay + el);
          const _currentDate = _date.toLocaleDateString("ru-RU");
          return { value: _currentDate, name: _currentDate };
        });
      case CellTypes.STAGE:
        return [...Array(10).keys()].map((el) => ({
          value: el + 1,
          name: el + 1,
        }));
      case CellTypes.PLATFORM:
        return [...Array(eventInfo.platforms).keys()].map((el) => ({
          value: el + 1,
          name: el + 1,
        }));
      default:
        return [];
    }
  };

  const getSeparationValues = (separation) => {
    if (typeof separation === "string")
      return {
        [StageType.All]: "",
        [StageType.Semifinal]: "",
        [StageType.Final1]: "",
        [StageType.Final3]: "",
      };
    return separation;
  };

  return (
    <>
      <div className={s.section}>
        <h3>Добавление трансляций по площадкам</h3>
        <br />
        <Stack alignItems={"flex-start"}>
          <Alert severity="info">
            Перейдите на нужную Вам трансляцию YouTube, нажмите кнопку
            "Поделиться". <br />
            После появления окна, нажмите "Копировать". Далее вставьте эту
            ссылку в поле ниже. <br />
            Проведите эту процедуру для каждой вашей трансляции.
          </Alert>
        </Stack>
        <br />
        <form onSubmit={handleSaveTranslations} method="post">
          <Grid container direction="column">
            {Array.from(Array(platformsNumber).keys()).map((platformIndex) => {
              const defaultURL =
                platformsURLs?.find(
                  (el) => el.platform === platformIndex + 1
                ) || "";
              return (
                <Grid
                  key={platformIndex}
                  container
                  justifyContent="flex-start"
                  alignItems="baseline"
                >
                  <Grid sx={{ minWidth: 30 }}>{platformIndex + 1}</Grid>
                  <Grid>
                    <Input
                      sx={{ minWidth: 300 }}
                      placeholder="URL трансляции"
                      defaultValue={defaultURL.url}
                      name={String(platformIndex + 1)}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <br />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSavingURLs}
          >
            Сохранить URLs
          </LoadingButton>
        </form>
        <br />
        <br />
        <h3 className={s.section__title}>
          Загрузка документа для генерации онлайн грамот
        </h3>
        <form method="POST" onSubmit={handleSendDocx} className={s.docxUpload}>
          <FileUpload onUpload={handleSetFile} />
          <LoadingButton loading={isLoading} variant="contained" type="submit">
            Отправить
          </LoadingButton>
        </form>
        <br />
        <CustomCheckbox
          label="Допуск спортсмена, только если он состоит в клубе"
          name={"withoutclub"}
          defaultChecked={+settings.withoutclub === 1}
          form={"presets-settings"}
        />
        <CustomCheckbox
          label="В случае нокаута, внесение спортсмена в черный список на 30 дней"
          name={"nokaut"}
          defaultChecked={+settings?.nokaut === 1}
          form={"presets-settings"}
        />
        <CustomCheckbox
          label='Добавить раздел "Награждение"'
          name={"winners"}
          checked={Boolean(winnersTab)}
          onChange={handleSetWinnersTab}
        />
        <br />

        <ControlledModal
          isOpen={separation.isOpen}
          onClose={handleCloseSeparation}
          title={<h3>Распределение</h3>}
        >
          <Separation
            onSet={separation.onSet}
            close={handleCloseSeparation}
            days={separation.days}
            defaultValues={separation.defaultValues}
          />
        </ControlledModal>

        {!isLoading && <Presets send={send} />}
      </div>
    </>
  );
};

export default EventSettings;
