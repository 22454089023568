import { Paper } from "@mui/material";
import s from "./Policy.module.sass";

export const UserAgreement = (props) => {
  return (
    <div className={s.informationPage}>
      <Paper className={s.wrapper}>
        <div>
          {" "}
          <h1>CombatArena Terms of Service</h1>
          <section id="quick-overview">
            <h2>Quick Overview</h2>
            <ul>
              <li>
                CombatArena collects information from your account and shares it
                with event organizers.
              </li>
              <li>
                If you become an academy manager, you are responsible for all
                the athletes you register.
              </li>
              <li>
                CombatArena complies with GDPR, and you can modify, hide, and
                delete your account.
              </li>
              <li>
                CombatArena is a non-commercial project and is provided free of
                charge to all users.
              </li>
              <li>
                CombatArena absolves event organizers from any liability, and
                all responsibility for the service lies with CombatArena.
              </li>
            </ul>
          </section>
          <section id="user-account">
            <h2>User Account</h2>
            <p>
              When creating an account, CombatArena creates a user profile for
              you to provide a more personalized experience. For more
              information on how CombatArena uses your information, please refer
              to the "Privacy Policy" section.
            </p>
            <p>
              During registration, you will be asked to either consent or not to
              receive newsletters, event reminders, and other promotional
              content (including information from our partners or third parties)
              that may be of interest to you via email, SMS, or other means.
            </p>
            <p>
              You can change your email preferences at any time in your account
              profile.
            </p>
          </section>
          <section id="privacy-policy">
            <h2>Privacy Policy & GDPR</h2>
            <p>
              At CombatArena, we recognize the importance of your privacy. This
              policy applies to all services and solutions offered by
              CombatArena.
            </p>
            <p>
              CombatArena stores and uses your information in compliance with
              European GDPR law (General Data Protection Regulation) (European
              Union: May 25, 2018).
            </p>
            <p>
              This privacy policy explains what personal data CombatArena
              collects online. Please read this policy carefully.
            </p>
          </section>
          <section id="information-collection">
            <h2>How We Collect Information About You</h2>
            <ul>
              <li>
                <strong>Cookies:</strong> Like many online services, we use
                cookies (small text files placed on your device) and tracking
                tools.
              </li>
              <li>
                <strong>Directly from you:</strong> For example, when you
                subscribe to an offer, create an account, register for an event,
                etc.
              </li>
              <li>
                <strong>Through your use of our websites and apps:</strong> We
                use tracking tools such as cookies and web beacons.
              </li>
              <li>
                <strong>From third parties:</strong> If you use social media
                integrated with our website or app, these platforms may provide
                us with certain information.
              </li>
            </ul>
          </section>
          <section id="data-usage">
            <h2>How We Use Your Information</h2>
            <ul>
              <li>
                For communications related to your transactions and events.
              </li>
              <li>To understand trends and interests.</li>
              <li>To enhance our products and services.</li>
              <li>To prevent illegal activities.</li>
              <li>For security purposes.</li>
              <li>For market research.</li>
              <li>To provide personalized advertising.</li>
              <li>For marketing purposes.</li>
            </ul>
          </section>
          <section id="user-rights">
            <h2>Your Rights and Choices</h2>
            <ul>
              <li>Withdrawing consent.</li>
              <li>Opting out of marketing communications.</li>
              <li>Managing tracking settings.</li>
            </ul>
          </section>
          <section id="aup">
            <h2>Acceptable Use Policy (AUP)</h2>
            <p>
              To maintain a safe and positive online environment, we have
              established the following Acceptable Use Policy.
            </p>
            <ul>
              <li>Illegal activities.</li>
              <li>Excessive risk.</li>
              <li>Unauthorized access or hacking.</li>
              <li>Malware.</li>
              <li>Phishing and social engineering.</li>
              <li>Denial of Service (DoS) attacks.</li>
              <li>Illegal content.</li>
              <li>Harassment and offensive content.</li>
              <li>Fraudulent activities.</li>
              <li>Violent or harmful content.</li>
              <li>Resource abuse.</li>
              <li>Impersonation.</li>
              <li>Spam and unsolicited messages.</li>
              <li>Privacy violations.</li>
            </ul>
          </section>
        </div>
      </Paper>
    </div>
  );
};
