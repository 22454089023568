import { useCallback, useEffect, useRef, useState } from "react";
import { userAPI } from "../../api/userAPI";
import { DataGrid } from "@mui/x-data-grid";
import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { debounce } from "lodash";
import { LoadingButton } from "@mui/lab";
import { ROOT_URL } from "../../constants";
import { downloadURI } from "../../helpers/downloadURI";

const FilterForm = ({ isLoading, onSearch, onReset }) => {
  const [fioOptions, setFioOptions] = useState([]);
  const [eventNameOptions, setEventNameOptions] = useState([]);
  const [fio, setFio] = useState(null);
  const [eventName, setEventName] = useState(null);
  const fioRef = useRef(null);
  const eventNameRef = useRef(null);

  const load = async ({ fio, eventName }) => {
    let result = [];
    try {
      result = await userAPI.getCertificationHistory({
        fio,
        event_name: eventName,
        count: 10,
        page: 0,
      });
    } catch (error) {
      console.log(error);
    }
    return result.data.data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());

    onSearch(data);
  };

  const handleChangeInput = (getData) => {
    return (e) => {
      getData(e.target.value);
    };
  };

  const debouncedFioHandler = useCallback(
    debounce(
      handleChangeInput((value) => {
        load({ fio: value }).then((data) => {
          setFioOptions(Array.from(new Set(data.map((el) => el.fio))));
        });
      }),
      1000
    ),
    []
  );

  const debouncedEventNameHandler = useCallback(
    debounce(
      handleChangeInput((value) => {
        load({ eventName: value }).then((data) => {
          setEventNameOptions(
            Array.from(new Set(data.map((el) => el.event_name)))
          );
        });
      }),
      1000
    ),
    []
  );

  const reset = () => {
    fioRef.current.value = "";
    eventNameRef.current.value = "";
    setFio(null);
    setEventName(null);
    onReset();
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="end">
        <Grid item xs={4}>
          <Autocomplete
            options={fioOptions}
            value={fio}
            onChange={(_, value) => setFio(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={fioRef}
                variant="standard"
                label="ФИО"
                placeholder="Начните вводить текст"
                name="fio"
                onChange={debouncedFioHandler}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            options={eventNameOptions}
            value={eventName}
            onChange={(_, value) => setEventName(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={eventNameRef}
                variant="standard"
                label="Название турнира"
                placeholder="Начните вводить текст"
                name="eventName"
                onChange={debouncedEventNameHandler}
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="outlined" fullWidth type="button" onClick={reset}>
            Сбросить
          </Button>
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            fullWidth
            type="submit"
          >
            Поиск
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

const CertificationHistory = () => {
  const DEFAULT_PAGE_SIZE = 100;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(0);

  const [fio, setFio] = useState("");
  const [eventName, setEventName] = useState("");

  const getTooltip = (content) => {
    return (
      <Tooltip
        enterDelay={1000}
        title={<Typography fontSize={14}>{content}</Typography>}
      >
        <span>{content}</span>
      </Tooltip>
    );
  };

  const columns = useRef([
    { field: "date", headerName: "Дата", width: 90 },
    {
      field: "number",
      headerName: "Номер приказа",
      width: 50,
      sortable: false,
    },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      renderCell: (params) => getTooltip(params.row.fio),
    },
    {
      field: "club",
      headerName: "Клуб",
      width: 250,
      renderCell: (params) => getTooltip(params.row.club),
    },
    {
      field: "event_name",
      headerName: "Турнир",
      width: 300,
      renderCell: (params) => getTooltip(params.row.event_name),
    },
    {
      field: "certification_from",
      headerName: "Разряд до",
      width: 200,
      renderCell: (params) => getTooltip(params.row.certification_from),
    },
    {
      field: "certification_to",
      headerName: "Разряд после",
      width: 200,
      renderCell: (params) => getTooltip(params.row.certification_to),
    },
    {
      field: "discipline",
      headerName: "Дисциплина",
      width: 200,
      renderCell: (params) => getTooltip(params.row.discipline),
    },
    {
      field: "file",
      headerName: "Файл",
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            variant="text"
            startIcon={<FileDownloadIcon />}
            onClick={() =>
              downloadURI("/certification/" + params.row.file, params.row.file)
            }
          >
            {params.row.file}
          </Button>
        );
      },
    },
  ]);

  const loadRows = useCallback(async ({ fio, eventName, page, count }) => {
    setIsLoading(true);
    try {
      const result = await userAPI.getCertificationHistory({
        fio,
        event_name: eventName,
        count,
        page,
      });
      setData(result.data.data);
      setAmount(result.data.amount);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadRows({ fio, eventName, page, count: pageSize });
  }, [loadRows, page, pageSize, fio, eventName]);

  const onSearch = ({ fio, eventName }) => {
    setData([]);
    setPage(0);
    setEventName(eventName);
    setFio(fio);
  };

  const onReset = () => {
    setEventName("");
    setFio("");
    setPageSize(DEFAULT_PAGE_SIZE);
    setPage(0);
  };

  return (
    <Stack
      spacing={3}
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: columns.current.reduce((acc, el) => acc + el.width, 2),
        margin: "0 auto",
      }}
    >
      <FilterForm isLoading={isLoading} onReset={onReset} onSearch={onSearch} />
      <DataGrid
        paginationMode="server"
        loading={isLoading}
        sx={{ minHeight: "600px" }}
        rows={data || []}
        columns={columns.current}
        rowCount={amount}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
      />
    </Stack>
  );
};
export default CertificationHistory;
