import { Paper } from "@mui/material";
import s from "./Policy.module.sass";

export const PrivacyPolicy = (props) => {
  return (
    <div className={s.informationPage}>
      <Paper className={s.wrapper}>
        <div>
          <h1>About CombatArena</h1>
          <p>
            CombatArena is a digital platform designed for event organizers,
            athletes, and federations. It is owned and operated by CombatArena,
            an Indian corporation. We are pleased to welcome you to our
            platform, which unites the largest community in the world for combat
            sports.
          </p>
          <p>
            CombatArena is a non-commercial project, and using the service is
            free of charge. All responsibility for the use of this website lies
            solely with the platform itself, releasing event organizers from any
            liability.
          </p>

          <h2>Privacy Policy and GDPR Compliance</h2>
          <p>
            At CombatArena, we understand the importance of data privacy. This
            policy applies to all our services and solutions.
          </p>
          <p>
            CombatArena processes and stores your data in accordance with the
            General Data Protection Regulation (GDPR), which has been in force
            in the European Union since May 25, 2018.
          </p>
          <p>
            This privacy policy describes the types of personal data that are
            collected online. We encourage you to read this policy carefully.
          </p>
          <p>
            We collect information when you create an account, contact us via
            email or phone, and when you use our websites, mobile applications,
            and social networks. This information is used to improve our
            services, provide support, and carry out marketing activities.
          </p>
          <p>
            For example, when you register an account or participate in events,
            we may request your consent to use your data for specific purposes.
            We share your data with event organizers so they can provide you
            with the necessary support for participation.
          </p>
          <p>
            CombatArena acts as the data controller, responsible for processing
            all the information collected in connection with the use of our
            services.
          </p>

          <h2>Ways We Collect Information</h2>
          <h3>Cookies</h3>
          <p>
            Like many other online services, we use cookies and similar
            technologies to collect technical information, which helps us
            deliver our services to you.
          </p>
          <p>
            <strong>This information may include:</strong>
          </p>
          <ul>
            <li>
              Details about the system and device you use to access our services
              (IP address, browser type and version, time zone settings,
              operating system, platform, and device identifiers).
            </li>
            <li>
              Information about the pages you visit, including full URLs and
              time of visits.
            </li>
            <li>
              Data about your use of mobile applications, viewed content, and
              the time you accessed it.
            </li>
            <li>
              Requests for access to third-party websites and services, as well
              as device configuration information.
            </li>
          </ul>

          <h2>Acceptable Use Policy (AUP)</h2>
          <p>
            To ensure a secure, respectful, and positive online environment for
            all users, we have developed this Acceptable Use Policy (AUP). By
            accessing or using CombatArena, you agree to comply with the terms
            outlined in this policy. Violating these rules may result in your
            account being blocked or legal action being taken.
          </p>

          <h3>Prohibited Activities</h3>
          <ul>
            <li>
              <strong>Illegal Activities:</strong> Any activity that violates
              laws, rules, or regulations, as well as actions that encourage
              others to break the law.
            </li>
            <li>
              <strong>Unauthorized Access or Hacking:</strong> Attempts to gain
              access to accounts, data, or systems on CombatArena without
              authorization.
            </li>
            <li>
              <strong>Malicious Software:</strong> Uploading or distributing
              malicious software, viruses, worms, or other programs that
              threaten security.
            </li>
            <li>
              <strong>Phishing and Social Engineering:</strong> Conducting
              phishing attacks to obtain sensitive information.
            </li>
            <li>
              <strong>Denial of Service (DoS) Attacks:</strong> Organizing or
              participating in activities aimed at disrupting service.
            </li>
            <li>
              <strong>Illegal Content:</strong> Posting or distributing content
              that violates laws or infringes intellectual property rights.
            </li>
            <li>
              <strong>Harassment and Offensive Content:</strong> Any form of
              online harassment, including hate speech or discrimination.
            </li>
            <li>
              <strong>Spam and Unsolicited Messages:</strong> Sending
              unsolicited messages, advertisements, or spam through the
              platform.
            </li>
          </ul>

          <h2>Our Responsibility for Data Security</h2>
          <p>
            We are committed to safeguarding and protecting your personal
            information. CombatArena adheres to recognized data protection
            standards, including Transport Layer Security (TLS) for encryption,
            limiting access to data, and storing it only as necessary for our
            business purposes.
          </p>

          <h2>Privacy Policy Changes</h2>
          <p>
            From time to time, we may make changes to our Privacy Policy. Please
            check your account regularly for updates.
          </p>
        </div>
      </Paper>
    </div>
  );
};
