import React from "react";
import { Link } from "react-router-dom"; // Импортируем Link
import styles from "./DocumentsPolicyContainer.module.css"; // Импорт модульных стилей

const DocumentsPolicyContainer = () => {
  return (
    <div className={styles.documentsPolicyContainer}>
      {/* Документы */}
      <div className={styles.documents}>
        <h3 className={styles.title}>Документы</h3>
        <Link to="#">Положение</Link>
        <Link to="#">Правила</Link>
        <Link to="#">Заявление для детей</Link>
        <Link to="#">Заявление для взрослых</Link>
      </div>

      {/* Карта */}
      <div className={styles.mapContainer}>
        <h3 className={styles.title}>Место проведения</h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2524.8804695648384!2d59.94799541573405!3d56.90548558087164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43cfa1106a20b9e3%3A0x7c4e73c5c7ce3c1b!2z0YPQuy4g0JvQtdC90LjQvdCwLCAzMSwg0J_QvtC70Y_QutC40Lkg0L_RgNC-0YHQvy4sINC_0L7QvdGB0LrQuNC5INC60L7RgtC-0YDQvtGB0YLRjCwgNjIyMDQ3!5e0!3m2!1sru!2sru!4v1633509630000!5m2!1sru!2sru"
          allowFullScreen=""
          loading="lazy"
          title="map"
        ></iframe>
      </div>

      {/* Политика возврата */}
      <div className={styles.policy}>
        <h3 className={styles.title}>Политика возврата</h3>
        <p>
          <s>До 01.10.2024: Возврат 100%</s>
        </p>
        <p>До 01.11.2024: Возврат 50%</p>
        <p>До 01.12.2024: Без возврата</p>
      </div>
    </div>
  );
};

export default DocumentsPolicyContainer;
