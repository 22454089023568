import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { userAPI } from "../../api/userAPI.js";
import { useEffect } from "react";
import { memo } from "react";
import { Box } from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import { size } from "lodash";
export default (function ShowNearWeight({ eventId, id, weight }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [weights, setWeigts] = React.useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await userAPI.getWeightCategory(eventId, id, weight);
   

    const dataWeight= Object.entries(result.data).map(([ key, val ]) => [key,val])
        
        setWeigts(dataWeight)
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ExpandMoreIcon sx={{ cursor: "pointer" }} />
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{overflow:'hidden', display:'flex', flexWrap:'wrap'}}>
           {weights?.map((value)=>
     <Box
    sx={{padding:"0 10px 10px 10px", height:'180px'}}
   
   >
    <ListItemText  sx={{textAlign:'center',color:'#fad744'}}>{value[0]}</ListItemText>
   <ListItemText   > Категория  {value[1].after} ({value[1].after_count})</ListItemText>
   <ListItemText > Категория  {value[1].after2} ({value[1].after2_count})</ListItemText>
   <ListItemText  sx={{  background: "#00800060" }}> Категория  {value[1].current} ({value[1].current_count})</ListItemText>
   <ListItemText> Категория  {value[1].before} ({value[1].before_count})</ListItemText>
   <ListItemText > Категория  {value[1].before2} ({value[1].before2_count})</ListItemText>
   </Box>)}
        </Box>
   
        
      
      </Popover>
    </div>
  );
});
