import { EventRegistrationStatus } from "../constants";
import BlockIcon from "@mui/icons-material/Block";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "styled-components";
import { Text } from "../language/lang";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const getUserStatus = ({ status, enableText = false }) => {
  const pasteText = (text) => (enableText ? text : "");

  if (
    status === EventRegistrationStatus.ALL ||
    status === EventRegistrationStatus.INSURANCE ||
    status === EventRegistrationStatus.MEDICAL ||
    status === EventRegistrationStatus.NONE
  ) {
    return (
      <Wrapper>
        <CreditScoreIcon sx={{ fill: "#dfcb11" }} />
        {pasteText(<>&nbsp;{Text.Participants.Status.Paid}</>)}
      </Wrapper>
    );
  }
  if (
    status === EventRegistrationStatus.WEIGHTED ||
    status === EventRegistrationStatus.PAID
  ) {
    return (
      <Wrapper>
        <CheckCircleIcon sx={{ fill: "green" }} />
        {pasteText(<>&nbsp;{Text.Participants.Status.Weighted}</>)}
      </Wrapper>
    );
  }
  if (status === EventRegistrationStatus.UNPAID) {
    return (
      <Wrapper>
        <CurrencyRubleIcon sx={{ fill: "gray" }} />
        {pasteText(<>&nbsp;{Text.Participants.Status.NotPaid}</>)}
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <BlockIcon sx={{ fill: "red" }} />
      {pasteText(<>&nbsp;{Text.Participants.Status.Rejected}</>)}
    </Wrapper>
  );
};
