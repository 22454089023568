import React, { useState } from "react";
import styles from "./ParticipantsList.module.scss";

const ParticipantsList = ({ participants }) => {
  const [sortState, setSortState] = useState({
    criteria: "Рейтинг", // Начальное значение сортировки
    direction: "desc", // Направление сортировки
  });

  const sortParticipants = (criteria) => {
    const direction =
      sortState.criteria === criteria && sortState.direction === "asc"
        ? "desc"
        : "asc";
    setSortState({ criteria, direction });
  };

  const sortedParticipants = [...participants].sort((a, b) => {
    let aValue, bValue;

    // Привязываем критерии к значениям в объектах участников
    switch (sortState.criteria) {
      case "ФИО":
        aValue = a.name.toLowerCase();
        bValue = b.name.toLowerCase();
        break;
      case "Дисциплина":
        aValue = a.discipline.toLowerCase();
        bValue = b.discipline.toLowerCase();
        break;
      case "Возраст":
        aValue = a.age;
        bValue = b.age;
        break;
      case "Вес":
        aValue = a.weight;
        bValue = b.weight;
        break;
      case "Рейтинг":
        aValue = a.rating;
        bValue = b.rating;
        break;
      case "Боев":
        aValue = a.fights;
        bValue = b.fights;
        break;
      case "Побед":
        aValue = a.wins;
        bValue = b.wins;
        break;
      case "Место":
        aValue = a.place.toLowerCase();
        bValue = b.place.toLowerCase();
        break;
      default:
        break;
    }

    return sortState.direction === "asc"
      ? aValue > bValue
        ? 1
        : -1
      : aValue < bValue
      ? 1
      : -1;
  });
  const placePart = (index) => {
    if (index === 0) return "#fff6c6";
    if (index === 1) return "#dfdfdf";
    if (index === 2) return "#eecba7";
  };
  return (
    <div className={styles.participantsList}>
      <div className={styles.participantsHeader}>
        {[
          "Место",
          "ФИО",
          "Дисциплина",
          "Возраст",
          "Вес",
          "Рейтинг",
          "Боев",
          "Побед",
        ].map((criterion) => (
          <div
            key={criterion}
            className={styles.sortArrow}
            onClick={() => sortParticipants(criterion)}
          >
            {criterion} <i className="fas fa-sort"></i>
          </div>
        ))}
      </div>
      {sortedParticipants.map((participant, i) => (
        <div key={participant?.id} className={styles.participantCard}>
          {/* <div
            className={styles.pdfIcon}
            onClick={() => downloadParticipantDiploma(participant?.id)}
          >
            <i className="fas fa-file-pdf"></i>
          </div> */}
          <div className={styles.place} style={{ background: placePart(i) }}>
            {participant?.place}
          </div>
          <div className={styles.name}>{participant?.name}</div>
          <div className={styles.discipline}>{participant?.discipline}</div>
          <div className={styles.age}>{participant?.age}</div>
          <div className={styles.weight}>{participant?.weight}</div>
          <div className={styles.rating}>{participant?.rating}</div>
          <div className={styles.fights}>{participant?.fights}</div>
          <div className={styles.wins}>{participant?.wins}</div>
        </div>
      ))}
    </div>
  );
};

// Функция для загрузки диплома участника
const downloadParticipantDiploma = (participantId) => {
  const link = document.createElement("a");
  link.href = `diplomas/participant_${participantId}.pdf`;
  link.download = `participant_diploma_${participantId}.pdf`;
  link.click();
};

export default ParticipantsList;
