import { CircularProgress, IconButton, TextField } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import styled from "styled-components";
import { EventRegistrationStatus } from "../../constants";
import { useParams } from "react-router-dom";
import { updateEventUser } from '../../redux/reducers/event/eventThunks.ts'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getEventState } from "../../redux/selectors/getEventState";
import { resetFetching, updateMember } from "../../redux/reducers/event/event.ts";

const Wrapper = styled.div`
    min-width: 140px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-shrink: 0;
`

const Actions = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

const Price = styled.div`
    white-space: nowrap;
`

const ProgressWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 48px;
`

const ConfirmPayment = ({ price, debt, paid, status, userID }) => {
    const dispatch = useDispatch()
    const { isFetching } = useSelector(getEventState)
    const { eventID } = useParams()
    const [paymentAmount, setPaymentAmount] = useState(+debt)
    const [label, setLabel] = useState('Долг')
    const isLoading = isFetching.updateUserStatus.userID === userID ? isFetching.updateUserStatus.status : false
    const isPaid = status === EventRegistrationStatus.WEIGHTED || status === EventRegistrationStatus.PAID

    useEffect(() => {
        if (isFetching.updateUserStatus.userID === userID) {
            if (isFetching.updateUserStatus.proceed) {
                dispatch(updateMember({
                    userID,
                    fields: {
                        paid: paid + Number(localStorage.getItem('paymentAmount')),
                        status: Number(localStorage.getItem('memberStatus'))
                    }
                }))
                dispatch(resetFetching())
                localStorage.removeItem('paymentAmount')
                localStorage.removeItem('memberStatus')
            }
        }
    }, [isFetching])

    const send = () => {
        const newStatus = debt === paymentAmount ? EventRegistrationStatus.NONE : status
        dispatch(updateEventUser({
            id: eventID,
            user_id: userID,
            status: newStatus,
            price: paymentAmount
        }))
        localStorage.setItem('paymentAmount', paymentAmount)
        localStorage.setItem('memberStatus', newStatus)
    }

    const handleChange = (e) => {
        setLabel('Сумма к оплате')
        const value = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value)
        setPaymentAmount(value < 0 ? 0 : value)
    }

    return (
        <Wrapper>
            <Price>Стоимость: {isPaid ? 0 : price}</Price>
            {isLoading && (
                <ProgressWrapper>
                    <CircularProgress />
                </ProgressWrapper>
            )}
            {!isLoading && (
                <Actions>
                    <TextField
                        sx={{
                            " input": {
                                backgroundColor: debt === 0 ? '#00800060' : 'rgba(0,0,0,0)'
                            }
                        }}
                        variant='standard'
                        label={label}
                        value={paymentAmount}
                        onChange={handleChange}
                        disabled={isLoading}
                    />
                    <IconButton onClick={send} disabled={isPaid}>
                        <DoneIcon />
                    </IconButton>
                </Actions>
            )}
        </Wrapper>
    )
}

export default ConfirmPayment;