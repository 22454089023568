import React, { useState } from "react";
import { IconButton, Button, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ControlledModal from "../ControlledModal/ControlledModal";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import s from "./ParticipantContainer.module.scss";
import { getCookie } from "../../AppExtComponents.ts";
import fromRuToISODateString from "../../helpers/fromRuToISODateString";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredParticipants } from "../../redux/reducers/event/event.ts";
import {
  deleteFromEvent,
  updateUserDataOnEvent,
} from "../../redux/reducers/event/eventThunks.ts";
import { getEventState } from "../../redux/selectors/getEventState";

const UserEditDelete = ({ rowData }) => {
  const [isEditUserModalOpen, setEditUserModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const dispatch = useDispatch();
  const deleteUser = () => {
    dispatch(deleteFromEvent({ id: event.id, user_id: rowData.id }));
    const updateParticipants = [...filteredParticipants].filter(
      (participant) => participant.id != rowData.id
    );

    dispatch(setFilteredParticipants(updateParticipants));
  };
  const { event, disciplinesNames, filteredParticipants } =
    useSelector(getEventState);
  const isAdmin = Boolean(getCookie("admin"));
  function getValidUnixDateRegister(date) {
    if (isNaN(Number(date))) {
      return fromRuToISODateString(date);
    } else {
      return +date;
    }
  }
  const editUser = (data) => {
    const updatedUserData = {
      id: event.id,
      user_id: rowData.id,
      status: rowData.status,
      phone: rowData.phone,
      email: rowData.email,
      comment: rowData.comment,
      trainer: rowData.trainer,
      weight: data.weight,
      disciplines: data.disciplines,
    };

    dispatch(updateUserDataOnEvent(updatedUserData));
    const updateParticipants = [...filteredParticipants].map((participant) => {
      if (participant.id === rowData.id) {
        return {
          ...participant,
          weight: data.weight,
          disciplines: data.disciplines,
        };
      } else {
        return participant;
      }
    });

    dispatch(setFilteredParticipants(updateParticipants));
  };

  const isAccess = () => {
    const userId = +getCookie("user");
    const checkableDate = new Date(
      event.date_end_registration
        ? getValidUnixDateRegister(event.date_end_registration)
        : +(event.unix_from * 1000)
    );
    const isAccessActive = new Date() < checkableDate;
    if (
      (userId === +rowData?.id || userId === +rowData?.trainer?.id) &&
      isAccessActive
    ) {
      return true;
    }
    return false;
  };

  const handleOpenEditUserModal = () => {
    setEditUserModalOpen(true);
  };

  const handleOpenDeleteUserModal = () => {
    setDeleteUserModalOpen(true);
  };

  const handleCloseEditUserModal = () => {
    setEditUserModalOpen(false);
  };

  const handleCloseUserDeleteModal = () => {
    setDeleteUserModalOpen(false);
  };

  const handleEditUser = (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    const weight = data.weight;
    delete data.weight;
    const checkedDisciplines = Object.keys(data);

    if (checkedDisciplines.length === 0) {
      alert("Выберите хотя бы одну дисциплину");
    } else {
      const updatedUserData = {
        id: event.id,
        user_id: rowData.id,
        weight: +weight,
        disciplines: checkedDisciplines,
      };
      editUser(updatedUserData);
      handleCloseEditUserModal();
    }
  };

  const handleDeleteUser = () => {
    deleteUser({ id: event.id, user_id: rowData.id });
    handleCloseUserDeleteModal();
  };

  return (
    <>
      {/* Edit and Delete buttons */}
      <IconButton
        onClick={handleOpenEditUserModal}
        disabled={!isAdmin && !isAccess()}
      >
        <i className="fas fa-edit" title="Изменить"></i>
      </IconButton>
      <IconButton
        onClick={handleOpenDeleteUserModal}
        disabled={!isAdmin && !isAccess()}
      >
        <i className="fas fa-trash-alt" title="Удалить"></i>
      </IconButton>

      {/* Edit User Modal */}
      <ControlledModal
        isOpen={isEditUserModalOpen}
        onClose={handleCloseEditUserModal}
        title={<h3>Редактирование участника</h3>}
      >
        <form className={s.editUser} onSubmit={handleEditUser}>
          Участник: {rowData.fio}
          <div className={s.editUser__disciplines}>
            {disciplinesNames.map((discipline) => (
              <CustomCheckbox
                key={discipline}
                defaultChecked={rowData.disciplines?.includes(discipline)}
                name={discipline}
                label={discipline}
              />
            ))}
          </div>
          <TextField
            label={"Вес"}
            defaultValue={rowData.weight}
            name="weight"
            fullWidth
          />
          <Button variant="contained" type="submit">
            Сохранить
          </Button>
        </form>
      </ControlledModal>

      {/* Delete User Modal */}
      <ControlledModal
        isOpen={isDeleteUserModalOpen}
        onClose={handleCloseUserDeleteModal}
        title={<h3>Удалить участника?</h3>}
      >
        Участник: {rowData.fio}
        <div className={s.finishButtons}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseUserDeleteModal}
          >
            Отмена
          </Button>
          <Button variant="contained" onClick={handleDeleteUser}>
            Удалить
          </Button>
        </div>
      </ControlledModal>
    </>
  );
};

export default UserEditDelete;
