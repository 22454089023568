import React, { useState, useEffect } from "react";
import ParticipantCard from "./ParticipantCard";
import Filters from "./Filters";
import styles from "./ParticipantContainer.module.scss";
import {
  getEventUsers,
  deleteFromEvent,
  updateUserDataOnEvent,
  getCountUsers,
  getUsersFileUrl,
  getEvent,
} from "../../redux/reducers/event/eventThunks.ts";
import { getCookie } from "../../AppExtComponents.ts";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEventState } from "../../redux/selectors/getEventState";
import {
  getBasketRules,
  updateBasketRules,
} from "../../redux/reducers/settings/settingsThunks";
import { setFilteredParticipants } from "../../redux/reducers/event/event.ts";
import getSettingsState from "../../redux/selectors/getSettingsState";
import getBasketState from "../../redux/selectors/getBasketState";
import { registrationStatus } from "../../constants.js";
import * as XLSX from "xlsx";
import Loading from "../Loading/Loading.jsx";

// Компонент для отображения списка участников и управления фильтрами
function ParticipantsContainerNew() {
  const { eventID } = useParams();
  const dispatch = useDispatch();
  // Состояние фильтров

  const {
    event,
    participants,
    filteredParticipants,
    disciplinesNames,
    usersFileUrl,
    countOfUsersAndClubs,
  } = useSelector(getEventState);
  const isFetching = {
    event: useSelector(getEventState).isFetching.gettingEvent,
    participant: useSelector(getEventState).isFetching.gettingParticipants,
    settings: useSelector(getSettingsState).fetching.isLoading,
    basket: useSelector(getBasketState).fetching.isLoading,
  };

  // Отображение фильтров
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const isAdmin = Boolean(getCookie("admin"));
  // Сортировка
  const [sortConfig, setSortConfig] = useState(null);
  const [resetFilterFlag, setResetFilterFlag] = useState(false);
  useEffect(() => {
    dispatch(getEvent({ id: eventID }));
    dispatch(
      getEventUsers({
        id: eventID,
        page: 1,
        count: 1000,
        user_id: getCookie("user") ? getCookie("user") : "",
      })
    );
    // dispatch(getBasketRules({ id: eventID }));
    dispatch(getCountUsers({ id: eventID }));
  }, []);

  useEffect(() => {
    dispatch(setFilteredParticipants([...participants]));
  }, [participants]);

  useEffect(() => {
    let copyFilter = [...filteredParticipants];
    if (sortConfig !== null && filteredParticipants.length > 0) {
      if (sortConfig.direction === "asc") {
        if (sortConfig.key === "fio") {
          copyFilter.sort((a, b) =>
            a?.[sortConfig.key]
              .toLowerCase()
              .localeCompare(b?.[sortConfig.key].toLowerCase())
          );
        } else {
          copyFilter.sort((a, b) => a?.[sortConfig.key] - b?.[sortConfig.key]);
        }
      } else if (sortConfig.direction === "desc") {
        if (sortConfig.key === "fio") {
          copyFilter.sort((a, b) =>
            b?.[sortConfig.key]
              .toLowerCase()
              .localeCompare(a?.[sortConfig.key].toLowerCase())
          );
        } else {
          copyFilter.sort((a, b) => b?.[sortConfig.key] - a?.[sortConfig.key]);
        }
      }

      dispatch(setFilteredParticipants([...copyFilter]));
    }
  }, [sortConfig]);
  // Функция для переключения отображения фильтров
  function toggleFilterVisibility() {
    setIsFilterVisible(!isFilterVisible);
  }

  // Функция для экспорта данных в Excel
  function exportToExcel() {
    const dataToExport = filteredParticipants.map((participant) => ({
      ФИО: participant.fio,
      Клуб: participant.club.name,
      Город: participant.city,
      Регион: participant.region,
      Страна: participant.country,
      "Дата рождения": participant.birthday,
      Возраст: participant.old,
      Вес: participant.weight + " кг",
      Разряд: participant.category,
      Пояс: participant.certification,
      Дисциплины: participant.disciplines.join(", "),
      Статус: registrationStatus[participant.status],
      Пол: participant.sex,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Участники");

    XLSX.writeFile(workbook, "Участники.xlsx");
  }

  // Функция для изменения сортировки
  function requestSort(key) {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  }

  // Функция для получения класса направления сортировки
  function getSortClassName(key) {
    if (!sortConfig || sortConfig.key !== key) {
      return "fas fa-sort";
    }
    return sortConfig.direction === "asc"
      ? "fas fa-sort-up"
      : "fas fa-sort-down";
  }
  const resetFilter = () => {
    dispatch(setFilteredParticipants(participants));
    setSortConfig(null);
    setResetFilterFlag(!resetFilterFlag);
  };

  return (
    <div className={styles.container}>
      {isFetching.participant ? (
        <Loading isLoading={isFetching.participant} />
      ) : (
        <>
          <button
            onClick={toggleFilterVisibility}
            className={styles.filterToggle}
          >
            {isFilterVisible ? "Скрыть" : "Фильтр"}
          </button>
          <button onClick={resetFilter} className={styles.filterToggle}>
            Сбросить
          </button>
          <Filters
            participants={participants}
            toggleFilterVisibility={toggleFilterVisibility}
            isFilterVisible={isFilterVisible}
            disciplinesNames={disciplinesNames}
            resetFilterFlag={resetFilterFlag}
          />
          {/* Опции сортировки и кнопка экспорта */}
          <div className={styles.statisticsSortContainer}>
            <div className={styles.statistics}>
              <div className={styles.statItem}>
                <i className="fas fa-users"></i> Участников:{" "}
                {countOfUsersAndClubs?.participants}
              </div>
              <div className={styles.statItem}>
                <i className="fas fa-user"></i> Спортсменов:{" "}
                {countOfUsersAndClubs?.users}
              </div>
              <div className={styles.statItem}>
                <i className="fas fa-shield"></i> Клубов:{" "}
                {countOfUsersAndClubs?.clubs}
              </div>
              <div className={styles.statItem}>
                <i className="fas fa-city"></i> Городов:{" "}
                {countOfUsersAndClubs?.cities}
              </div>
              <div className={styles.statItem}>
                <i className="fas fa-map-marker-alt"></i> Регионов:
                {countOfUsersAndClubs?.regions}
              </div>
              <div className={styles.statItem}>
                <i className="fas fa-globe"></i> Стран:{" "}
                {countOfUsersAndClubs?.countries}
              </div>
            </div>
            <div className={styles.sortOptions}>
              <div
                className={styles.sortOption}
                onClick={() => requestSort("fio")}
              >
                <span>По алфавиту</span>
                <i className={getSortClassName("fio")}></i>
              </div>
              <div
                className={styles.sortOption}
                onClick={() => requestSort("old")}
              >
                <span>По возрасту</span>
                <i className={getSortClassName("old")}></i>
              </div>
              <div
                className={styles.sortOption}
                onClick={() => requestSort("weight")}
              >
                <span>По весу</span>
                <i className={getSortClassName("weight")}></i>
              </div>
              {isAdmin && (
                <button
                  onClick={exportToExcel}
                  className={styles.downloadButton}
                >
                  <i className="fas fa-download"></i> Скачать
                </button>
              )}
            </div>
          </div>

          {/* Список участников */}
          <div
            className={`${styles.participantsContainer} ${
              filteredParticipants.length < 4 ? styles.countCard : ""
            }`}
          >
            {filteredParticipants.map((participant, index) => (
              <ParticipantCard
                key={index}
                participant={participant}
                isAdmin={isAdmin}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ParticipantsContainerNew;
