import ShowNearWeight from "./ShowNearWeight";
import { IconButton, TextField } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useState, memo } from "react";
import { eventAPI } from "../../api/eventAPI";

export default (function WeightParticipant({ eventId, rowData }) {
  const [labelweight, setLabelWeight] = useState(rowData.weight);
  const [changeData, setChangeData] = useState(rowData.weight);

  const handleSaveWeight = () => {
    const object = {
      id: eventId,
      user_id: rowData.id,
      status: 4,
      weight: labelweight,
    };
    try {
      eventAPI.updateEventUser(object).then((r) => {
        if (r.ok) setChangeData(labelweight);
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
      {" "}
      <ShowNearWeight
        eventId={eventId}
        id={rowData.id}
        weight={changeData}
      />{" "}
      <div style={{ display: "flex" }}>
        <TextField
          sx={{
            " input": {
              backgroundColor:
                labelweight === changeData ? "#00800060" : "rgba(0,0,0,0)",
              width: "45px",
            },
          }}
          id="standard-basic"
          type="number"
          variant="standard"
          label={"кг"}
          value={labelweight}
          onChange={(event) => setLabelWeight(event.target.value)}
        />
        <IconButton onClick={handleSaveWeight}>
          <DoneIcon />
        </IconButton>
      </div>
    </div>
  );
});
