import { connect, useDispatch } from "react-redux";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../AppExtComponents.ts";
import { setForceRerender } from "../../redux/reducers/user.ts";
import { setUserID } from "../../redux/reducers/auth.ts";
import HeaderLeft from "./HeaderLeft.jsx";
import { useState } from "react";

const HeaderCC = (props) => {
  const nav = useNavigate();
  const [hideMenu, setHideMenu] = useState(true);

  const redirectToAccount = () => {
    if (getCookie("user") === undefined) {
      nav(`sign`);
    } else {
      nav(`user/${getCookie("user")}`);
      props.setForceRerender(true);
      props.setUserID(+getCookie("user"));
    }
  };

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        width: hideMenu ? "180px" : "50px",
        height: "100vh",
        backgroundColor: "#f8f9fa",
        transition: "width 0.3s ease",
        zIndex: 1000,
      }}
    >
      <HeaderLeft
        hideMenu={hideMenu}
        setHideMenu={setHideMenu}
        redirectToAccount={redirectToAccount}
      />
    </div>

    // <Header redirectToAccount={redirectToAccount} />
  );
};

let mapState = (state) => ({
  isFetching: state.auth.isFetching,
  userID: state.auth.userID,
});

export default connect(mapState, {
  setForceRerender,
  setUserID,
})(HeaderCC);
