import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Li = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding: ${(props) => (props.isAction ? "0px" : "8px")} 16px;
  border-radius: 10px;
  & a {
    color: black;
    text-decoration: none;
    &:hover {
      color: #0066d1;
    }
  }
`;

const ActionLi = ({
  isNav = false,
  to = "",
  children,
  endAction = null,
  onClick,
}) => {
  return (
    <Li isAction={Boolean(endAction)} onClick={onClick}>
      {isNav && <NavLink to={to}>{children}</NavLink>}
      {!isNav && <span>{children}</span>}
      {endAction}
    </Li>
  );
};

export default ActionLi;
