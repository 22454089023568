import { Box } from "@mui/material";
import { GridCompetitionsCC } from "../../components/GridCompetitions/GridCompetitionsCC";
import GridCompetitionsFilter from "../../components/filters/GridCompetitionsFilter";
import s from "./Competitions.module.sass";
import { useSelector } from "react-redux";

const Competitions = () => {
  const { events } = useSelector((state) => ({
    events: state.selection.events,
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <GridCompetitionsFilter />
      <div className={s.competitions}>
        <div
          className={s.wrapper}
          style={events.length !== 0 ? { maxWidth: "1500px" } : {}}
        >
          <GridCompetitionsCC />
        </div>
      </div>
    </Box>
  );
};

export default Competitions;
