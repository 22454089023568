import { Box, Button, ButtonGroup, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { getCookie } from "../../AppExtComponents.ts";
import {
  getEvents,
  resetEvents,
  setEventsSelectionMode,
} from "../../redux/reducers/selection.ts";
import { useEffect, useState } from "react";
import { Text } from "../../language/lang";
import s from "./GridFilter.module.scss";
import { Link } from "react-router-dom";

const GridCompetitionsFilter = () => {
  const isAdmin = getCookie("admin");
  const dispatch = useDispatch();
  const [selection, setSelection] = useState("future");
  const [hide, setHide] = useState(false);
  useEffect(() => {
    dispatch(setEventsSelectionMode(selection));
    dispatch(getEvents({ count: 12, page: 1, time: selection }));
    dispatch(resetEvents());
  }, [selection]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box className={s.filter}>
          <Button
            className={`${s.btn}  ${s.activeButton}`}
            onClick={() => setHide(!hide)}
          >
            {hide ? "Скрыть" : "Фильтр"}
          </Button>
          <ButtonGroup
            className={s.buttonGroup}
            aria-label="medium outlined button group"
          >
            <Button
              className={`${s.btn} ${s.filterButton}  ${
                selection === "all" && s.activeButton
              }`}
              onClick={() => setSelection("all")}
            >
              {Text.Filter.All}
            </Button>
            <Button
              className={`${s.btn} ${s.filterButton}  ${
                selection === "future" && s.activeButton
              }`}
              onClick={() => setSelection("future")}
            >
              {Text.Filter.FutureEvents}
            </Button>
            <Button
              className={`${s.btn} ${s.filterButton}  ${
                selection === "past" && s.activeButton
              }`}
              onClick={() => setSelection("past")}
            >
              {Text.Filter.PastEvents}
            </Button>
          </ButtonGroup>
          {isAdmin && (
            <Link to={"/admin"}>
              <Button className={`${s.btn}  ${s.activeButton}`}>
                Создать турнир
              </Button>
            </Link>
          )}
        </Box>
        {hide && (
          <Box className={s.hideFilter}>
            <input className={s.textField} placeholder="Название"></input>
            <input className={s.date} type="date" />
            <input className={s.date} type="date" />
            <input className={s.textField} placeholder="Страна"></input>
            <input className={s.textField} placeholder="Область"></input>
            <input className={s.textField} placeholder="Город"></input>
            <input className={s.textField} placeholder="Уровень"></input>
            <input
              className={s.textField}
              style={{ width: "80px" }}
              placeholder="Лет"
            ></input>
            <input
              className={s.textField}
              style={{ width: "80px" }}
              placeholder="Год"
            ></input>
            <input
              className={s.textField}
              style={{ width: "130px" }}
              placeholder="Вид единоборств"
            ></input>
            <input className={s.textField} placeholder="Организатор"></input>
            <input
              className={s.textField}
              placeholder="Этап регистрации"
            ></input>
            <Button
              className={`${s.btn}  ${s.activeButton}`}
              onClick={() => setSelection("all")}
            >
              Поиск
            </Button>
            <Button
              className={`${s.btn}  ${s.activeButton}`}
              onClick={() => setSelection("all")}
            >
              Сбросить
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
export default GridCompetitionsFilter;
