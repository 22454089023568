import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import getEventPrice from "../../helpers/getEventPrice";
import { resetPaymentState } from "../../redux/reducers/payment/payment";
import { setFetching } from "../../redux/reducers/event/event.ts";
import { Text } from "../../language/lang";
import { ROOT_URL } from "../../constants";
import { Alert, AlertTitle, Box, Stack } from "@mui/material";

const RegistrationPayment = () => {
  const { eventId, users, price, discount, account } = useSelector(
    (state) => state.payment
  );
  const dispatch = useDispatch();
  const targets =
    "%D0%92%D0%B7%D0%BD%D0%BE%D1%81%20%D0%BD%D0%B0%20%D1%81%D0%BE%D1%80%D0%B5%D0%B2%D0%BD%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5";
  const targetURL = `${ROOT_URL}/success_yoomoney.php`;
  const descriptionRef = useRef(null);

  useEffect(() => {
    parseDescription(Text.Payment.PaymentDescription);
    return () => {
      dispatch(resetPaymentState());
      dispatch(
        setFetching({
          register: {
            status: false,
            proceed: false,
          },
        })
      );
    };
  }, [dispatch]);

  const getSum = () => {
    return users.reduce((prev, current) => {
      return (
        prev +
        getEventPrice({ price, discount, disciplines: current.disciplines })
      );
    }, 0);
  };

  const getSuccessURL = () => {
    return encodeURI(
      targetURL + `?id=${eventId};${users.map((el) => el.id).join(",")}`
    );
  };

  const parseDescription = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    descriptionRef.current.innerHTML = doc.querySelector("body").innerHTML;
  };

  return (
    <Stack spacing={2}>
      <span ref={descriptionRef}></span>
      <Alert severity="warning">
        <AlertTitle>{Text.Payment.Warning}</AlertTitle>
        {Text.Payment.Alert}
      </Alert>
      <b>
        {Text.Payment.ToBePaid}: {getSum()}руб.
      </b>
      <Box sx={{ textAlign: "center", color: "#2f80ed", fontWeight: 700 }}>
        Оплата через сайт не проводится! Уточняйте у организатора о способах
        оплаты взноса на турнир
      </Box>
      {/* <iframe
                title=" "
                src={`https://yoomoney.ru/quickpay/button-widget?targets=${targets}&default-sum=${getSum()}&button-text=12&yoomoney-payment-type=on&button-size=m&button-color=white&successURL=${getSuccessURL()}&quickpay=small&account=${account}&`}
                width="320"
                height="46"
                frameborder="0"
                allowtransparency="true"
                scrolling="no">
            </iframe> */}
    </Stack>
  );
};

export default RegistrationPayment;
